import { getSession } from "../../contexts/auth";

export var isAdmin = null
export var isAssociate = null
export var isClient = null

export var notAdmin = null
export var notAssociate = null
export var notClient = null

export const resetPermissions = () => {
    const newuser = getSession()

    const groups = newuser?.groups
    
    const bits = groups?.bits

    isAdmin = Boolean(1&bits)
    isAssociate = Boolean(2&bits)
    isClient = Boolean(4&bits)
    notAdmin = Boolean(1&~bits)
    notAssociate = Boolean(2&~bits)
    notClient = Boolean(4&~bits)
}


