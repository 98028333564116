import React, { useEffect, useReducer, useState } from 'react'

import { Box, Divider, IconButton, TextField, Tooltip } from '@mui/material'
import dayjs from 'dayjs'
import IntegerOnly from '../Utils/IntegerOnly'
import { defaultReducer } from '../Utils/reducers'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationDialog from '../Utils/ConfirmationDialog'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { AddModal } from './addround'
import AddMatch from './addmatch'
import { weekdayDePara } from './championship-table'

const Match = ({ setEditMatch, data, parent, setParent }) => {

    const [state, setState] = useReducer(defaultReducer, {
        ...data
    })

    const [deleteModal, setDeleteModal] = useState()

    useEffect(() => {
        setState(data)
    }, [data, parent])

    return (
        <>
            {deleteModal && <ConfirmationDialog
                handleClose={() => setDeleteModal(null)}
                onConfirm={() => {
                    parent.matches = parent.matches.filter(each => each !== data)
                    setParent({ ...parent })
                    setDeleteModal(null)
                }}
                content={<Box sx={{ whiteSpace: 'normal' }}>Deseja excluir a partida selecionada? <b>{state.teams[0].name} x {state.teams[1].name}</b></Box>}
            />}
            <Box className='match-row'>
                <Box className='match-item'>
                    {state.category.name}
                </Box>
                <Box className='half-match-item'>
                    {state.group}
                </Box>
                <Box className='match-item'>
                    {state.teams[0].name}
                </Box>
                <TextField
                    size='small'
                    inputProps={{ maxLength: 2 }}
                    value={state.teams[0].score}
                    onChange={e => {
                        state.teams[0].score = IntegerOnly(e.target.value, 0, 99)
                        const match = parent.matches.find(each => each === data)
                        Object.assign(match, state)
                        setState({ ...state })
                    }} />
                x
                <TextField
                    size='small'
                    inputProps={{ maxLength: 2 }}
                    value={state.teams[1].score}
                    onChange={e => {
                        state.teams[1].score = IntegerOnly(e.target.value, 0, 99)
                        const match = parent.matches.find(each => each === data)
                        Object.assign(match, state)
                        setState({ ...state })
                    }} />
                <Box className='match-item'>
                    {state.teams[1].name}
                </Box>
                <Box>
                    {state.time}
                </Box>
                <Tooltip title='Editar partida'><IconButton onClick={() => {setEditMatch(data)}}><EditIcon /></IconButton></Tooltip>
                <Tooltip title='Excluir partida'><IconButton onClick={() => setDeleteModal(data)}><DeleteIcon /></IconButton></Tooltip>
            </Box>
        </>
    )
}

const Round = ({ generalData, index, data, parent, setParent }) => {

    const [state, setState] = useReducer(defaultReducer, {
        ...data
    })

    const [deleteModal, setDeleteModal] = useState()
    const [editModal, setEditModal] = useState()
    const [editMatch, setEditMatch] = useState()

    useEffect(() => {
        setState(data)
    }, [data])

    const change = () => {
        const round = parent.table.find(each => each === data)
        Object.assign(round, state)
    }

    return (
        <Box className='round-card'>
            {deleteModal && <ConfirmationDialog
                handleClose={() => setDeleteModal(null)}
                onConfirm={() => {
                    parent.table = parent.table.filter(each => each !== data)
                    setParent({ ...parent })
                    setDeleteModal(null)
                }}
                content={<Box sx={{ whiteSpace: 'normal' }}>Deseja excluir a rodada selecionada? <b>Rodada {index + 1}</b></Box>}
            />}
            {editModal && <AddModal
                generalData={generalData}
                data={editModal}
                parent={parent}
                setParent={setParent}
                setShowModal={setEditModal}
                index={index}
            />}
            {editMatch && <AddMatch 
                generalData={generalData}
                data={editMatch}
                parent={state}
                setParent={setState}
                setShowModal={setEditMatch}
                change={change}
            />}
            <Box className='round-flex'>
                <Box>Rodada {index + 1},</Box>
                <Box>Local: {data.school.name},</Box>
                <Box>Data: {dayjs(data.actual_date).toDate().toLocaleDateString()}, {weekdayDePara[dayjs(data.actual_date).$W]}</Box>
                <Box className='match-row'>
                    <Tooltip title='Editar rodada'><IconButton onClick={() => setEditModal(data)}><EditIcon /></IconButton></Tooltip>
                    <Tooltip title='Excluir rodada'><IconButton onClick={() => { setDeleteModal(data) }}><DeleteIcon /></IconButton></Tooltip>
                </Box>
            </Box>
            <Divider />
            <Box className='match-columns'>
                {data.matches.map(match => (<Match setEditMatch={setEditMatch} data={match} parent={state} setParent={(e) => { setState(e); change() }} />))}
            </Box>
            <Box>
                <Tooltip title='Nova partida'><IconButton onClick={() => setEditMatch({}) } ><AddCircleOutlineIcon /></IconButton></Tooltip>
            </Box>
        </Box>
    )
}

export default Round