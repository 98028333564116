import React, { useContext, useReducer, useEffect, useState } from "react";
import { Box, InputLabel, TextField, Tooltip } from "@mui/material";
import { defaultReducer } from "../Utils/reducers";
import ColorButton from "../Buttons/ColorButton";
import { externalComponent } from "../AppRoutes";
import { api } from "../../services/api";

import ExceptionList from "./exception-list";
import TimeTableTeams from "./timetable-teams";
import TimeTableSchools from "./timetable-schools";
import Locals from "./locals";
import ConfirmationDialog from "../Utils/ConfirmationDialog";
import TeamsTable from "./teams-table";
import DefaultModal from "../Utils/DefaultModal";
import { weekdayDePara } from "./championship-table";

//testcase
const calculate = (teamslength) => {
  const amountofGroups = Array(
    calcDivisionEquation(teamslength > 2 ? teamslength : 2)
  )
    .fill(1)
    .map((each, index) => String.fromCharCode(index + 65));
  console.log(
    Array(teamslength)
      .fill(1)
      .map((each, index) => ({
        [index]:
          amountofGroups[
            Math.floor((index / teamslength) * amountofGroups.length)
          ],
      }))
  );
};

export const arrayRange = (start, stop, step = 1) => {
  if (stop < start) stop = stop + 7;
  //if (stop === start) stop = stop + 7;
  return Array.from(
    { length: (stop - start) / step + 1 },
    (value, index) => (start + index * step) % 7
  );
};

const findDisponibility = (data, index = 0, type = "teams") => {
  return data.disponibilities
    .filter((each) =>
      arrayRange(Number(each.weekdayini), Number(each.weekdayend)).includes(
        index
      )
    )
    .map((each) => ({
      name: each.name,
      idx: each.idx,
      timeend: each.timeend,
      timeini: each.timeini,
    }));
};

const createHourTable = async (teams, setLoader, setState, maxGroups) => {
  setLoader(true);
  let teams_result = {};
  let schools_result = {};
  const res = await api.post("/school/teams/", {
    teams: teams.map((each) => each.id),
  });
  if (res.status === 200)
    schools_result = Object.fromEntries(
      res.data.map((school) => [
        school.id,
        {
          id: school.id,
          name: school.name,
          teams: school.teams,
          dom: findDisponibility(school, 0),
          seg: findDisponibility(school, 1),
          ter: findDisponibility(school, 2),
          qua: findDisponibility(school, 3),
          qui: findDisponibility(school, 4),
          sex: findDisponibility(school, 5),
          sab: findDisponibility(school, 6),
          type: "schools",
        },
      ])
    );

  const amountOfGroups = Array(calcDivisionEquation(maxGroups))
    .fill(1)
    .map((each, index) => String.fromCharCode(index + 65));

  teams.map((team, index) => {
    teams_result[team.id] = {
      id: team.id,
      name: team.name,
      categories: team.categories,
      dom: findDisponibility(team, 0),
      seg: findDisponibility(team, 1),
      ter: findDisponibility(team, 2),
      qua: findDisponibility(team, 3),
      qui: findDisponibility(team, 4),
      sex: findDisponibility(team, 5),
      sab: findDisponibility(team, 6),
      type: "teams",
      group:
        amountOfGroups[
          Math.floor((index / teams.length) * amountOfGroups.length)
        ],
    };
    return 1;
  });

  setState({
    teams: Object.values(teams_result),
    schools: Object.values(schools_result),
  });

  setLoader(false);
};

const calcDivisionEquation = (amount = 0) => {
  return Math.round(1.1073 * amount ** 0.4882);
};

const clearDisponibilities = (state, setState, place) => {
  const weekdays = Object.values(weekdayDePara);
  state[place].map((each) => {
    weekdays.map((day) => (each[day] = []));
  });
  setState({ ...state });
};

let timer = 0;

const TimeTable = ({ parent, setParent }) => {
  const { isMobile, setLoader } = useContext(externalComponent);
  const [maxGroups, setMaxGroups] = useState(1);

  const [state, setState] = useReducer(defaultReducer, {
    schools: [],
    teams: [],
    exceptions: [],
    ...parent.timetable,
    //...JSON.parse(JSON.stringify(parent.timetable))
  });

  useEffect(() => {
    setState({ ...parent.timetable });
  }, [parent, setState]);

  const [showTeams, setShowTeams] = useState(true);
  const [showSchools, setShowSchools] = useState(true);
  const [addLocals, setAddLocals] = useState(false);
  const [confirmRecreate, setConfirmRecreate] = useState(false);
  const [manageTeams, setManageTeams] = useState(false);
  const [clearDisp, setClearDisp] = useState(null);
  const [exceptionList, setExceptionList] = useState(false);

  useEffect(() => {
    parent.timetable = state;
  }, [state, parent]);

  const pushNewParent = (school) => {
    const newParent = {
      id: school.id,
      name: school.name,
      dom: findDisponibility(school, 0),
      seg: findDisponibility(school, 1),
      ter: findDisponibility(school, 2),
      qua: findDisponibility(school, 3),
      qui: findDisponibility(school, 4),
      sex: findDisponibility(school, 5),
      sab: findDisponibility(school, 6),
      type: "schools",
      exclude: 1,
    };
    setState({ schools: [newParent, ...state.schools] });
    setAddLocals(false);
  };

  return (
    <Box className="teams-container">
      {manageTeams && (
        <DefaultModal
          title="Gerenciar Times"
          handleClose={() => {
            setManageTeams(false);
          }}
          content={
            <TeamsTable
              parent={parent}
              setParent={setParent}
              setLoader={setLoader}
              removeTeam={(team) => {
                setState({teams: []})
              }}
              addTeam={(team) => {
                const oldTeam = state.teams.find(t => t.id === team.id)
                const newteam = {
                  id: team.id,
                  name: oldTeam?.name || team.name,
                  categories: oldTeam?.categories || team.categories,
                  dom: oldTeam?.dom || findDisponibility(team, 0),
                  seg: oldTeam?.seg || findDisponibility(team, 1),
                  ter: oldTeam?.ter || findDisponibility(team, 2),
                  qua: oldTeam?.qua || findDisponibility(team, 3),
                  qui: oldTeam?.qui || findDisponibility(team, 4),
                  sex: oldTeam?.sex || findDisponibility(team, 5),
                  sab: oldTeam?.sab || findDisponibility(team, 6),
                  type: "teams",
                  group: oldTeam?.group || 'A',
                }
                setState({teams: [...state.teams.filter(t=> t.id !== team.id ), newteam]})

              }}
            />
          }
        />
      )}
      {exceptionList && (
        <ExceptionList
          parent={state}
          setParent={setState}
          setLoader={setLoader}
          handleClose={() => setExceptionList(false)}
          timetable={parent.timetable}
        />
      )}

      {confirmRecreate && (
        <ConfirmationDialog
          content="Deseja recalcular a tabela de horas? Isso sobrescreverá a tabela anterior."
          handleClose={() => {
            setConfirmRecreate(false);
          }}
          onConfirm={() => {
            createHourTable(parent.teams, setLoader, setState, maxGroups);
            setConfirmRecreate(false);
          }}
        />
      )}
      {clearDisp && (
        <ConfirmationDialog
          content="Deseja zerar a disponibilidade da tabela selecionada?."
          handleClose={() => {
            setClearDisp(null);
          }}
          onConfirm={() => {
            clearDisponibilities(state, setState, clearDisp);
            setClearDisp(null);
          }}
        />
      )}
      {addLocals && (
        <Locals
          parent={state}
          submit={pushNewParent}
          handleClose={() => setAddLocals(false)}
        />
      )}
      {!parent.initiated && (
        <Box className="school-header">
          <Tooltip title={parent.teams.length === 0 ? "Adicione um time" : ""}>
            <span style={{ width: "100%", flex: "9" }}>
              <ColorButton
                sx={{ width: "100%", flex: "9" }}
                disabled={parent.teams.length === 0}
                onClick={() => {
                  parent?.timetable?.schools?.length === 0
                    ? createHourTable(
                        parent.teams,
                        setLoader,
                        setState,
                        maxGroups
                      )
                    : setConfirmRecreate(true);
                }}
              >
                Calcular tabela de horas
              </ColorButton>
            </span>
          </Tooltip>

          <span style={{ width: "100%", flex: "1", fontSize: ".85rem" }}>
            <ColorButton
              sx={{ width: "100%", flex: "1", fontSize: ".85rem" }}
              onClick={() => {
                setExceptionList(true);
              }}
            >
              Gerenciar Horarios
            </ColorButton>
          </span>
        </Box>
      )}

      <Box className="school-header">
        <Box className="school-header" sx={{ flex: 1 }}>
          <ColorButton
            sx={{ flex: 6 }}
            onClick={() => setShowTeams((p) => !p)}
            reverse={!showTeams}
          >
            Times
          </ColorButton>

          {/* {!parent.initiated && <TextField size='small' sx={{ flex: 2, background: 'white' }} value={maxGroups} onChange={(e) => setMaxGroups(e.target.value)} label='Limite de chaves' />} */}
        </Box>

        <Box className="school-header" sx={{ flex: 1 }}>
          <ColorButton
            sx={{ flex: 8 }}
            onClick={() => setShowSchools((p) => !p)}
            reverse={!showSchools}
          >
            Localidades
          </ColorButton>
        </Box>
      </Box>
      <Box className="menu-divider">
        <Box
          className="school-container"
          sx={{
            minWidth: isMobile ? "100%" : "1rem",
            display: showTeams ? "block" : "none",
            flex: 1,
          }}
        >
          <Box sx={{ height: "100%" }}>
            <TimeTableTeams
              parent={state}
              setParent={setState}
              disabled={parent.initiated}
              extraComponent={
                <>
                  {!parent.initiated && (
                    <>
                      <ColorButton
                        className="table-buttons-config"
                        onClick={() => {
                          setManageTeams(true);
                        }}
                        reverse={true}
                      >
                        Gerenciar times
                      </ColorButton>
                      <ColorButton
                        className="table-buttons-config"
                        onClick={() => {
                          setClearDisp("teams");
                        }}
                        reverse={true}
                      >
                        Zerar disp.
                      </ColorButton>
                    </>
                  )}
                </>
              }
            />
          </Box>
        </Box>
        <Box
          className="school-container"
          sx={{
            minWidth: isMobile ? "100%" : "1rem",
            display: showSchools ? "block" : "none",
            flex: 1,
          }}
        >
          <Box sx={{ height: "100%" }}>
            <TimeTableSchools
              parent={state}
              setParent={setState}
              disabled={parent.initiated}
              extraComponent={
                <>
                  {!parent.initiated && (
                    <>
                      <ColorButton
                        className="table-buttons-config"
                        onClick={() => {
                          setAddLocals(true);
                        }}
                        reverse={true}
                      >
                        Nova localidade
                      </ColorButton>
                      <ColorButton
                        className="table-buttons-config"
                        onClick={() => {
                          setClearDisp("schools");
                        }}
                        reverse={true}
                      >
                        Zerar disp.
                      </ColorButton>
                    </>
                  )}
                </>
              }
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TimeTable;
