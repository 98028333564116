import React, { useEffect, useReducer } from "react";
import { Box, IconButton } from "@mui/material";
import ColorButton from "../Buttons/ColorButton";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import DefaultModal from "../Utils/DefaultModal";
import { defaultReducer } from "../Utils/reducers";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ExceptionModal from "./exception-modal";
import dayjs from "dayjs";

const columns = (state, setState) => {
  
  

  return [
    {
      field: "descricao",
      headerName: "Descrição",
      flex: 0.4,
    },

    {
      field: "date",
      headerName: "Data",
      flex: 0.4,
      renderCell: ({value}) => {
        return dayjs(value).format('DD/MM/YYYY')
      }
    },

    {
      field: "timeini",
      headerName: "Horario de Inicio",
      flex: 0.4,
      renderCell: ({value}) => {
        return dayjs(value).format('HH:mm')
      }
    },
    {
      field: "timeend",
      headerName: "Horario de fim",
      flex: 0.4,
      renderCell: ({value}) => {
        return dayjs(value).format('HH:mm')
      }
    },

    {
      field: "permission",
      headerName: "Permissão",
      flex: 0.4,
      renderCell: ({value}) => {
        if (value === true) {
          return "Permitir"
        } else {
          return "Não Permitir"
        }
        
      }
    },

    {
      field: "actions",
      headerName: "Ações",
      flex: 0.4,
      renderCell: (props) => {
        return (
          <Box>
            <IconButton
              onClick={() => setState({ exceptionModal: props.row, exceptionType: 'single'})}
            >
              <EditIcon />
            </IconButton>

            <IconButton onClick={() => { setState({ exceptions: state.exceptions.filter(each => each.id !== props.id) }) }}><DeleteIcon /></IconButton>
          </Box>
        );
      },
    },
  ];
};

const ExceptionList = ({
  setLoader,
  parent,
  setParent,
  handleClose,
  timetable,
}) => {
  const [state, setState] = useReducer(defaultReducer, {
    exceptions: parent?.exceptions?.length > 0 ? parent.exceptions : [],
    exceptionModal: null,
    exceptionType : 'single'
  });

  const closeSetException = () => {
    setParent({ exceptions: state.exceptions });
    handleClose();
  };

  useEffect(() => {
    parent.timetable = state;
  }, [state, parent]);

  return (
  <>
    <DefaultModal
      title="Exceções de horário"
      handleClose={closeSetException}
      dialogProps={{ maxWidth: "sm" }}
      content={
        <>
          <Box className='teams-container'  sx={{ minHeight: '30rem', maxHeight:'35rem' }}>
            <Box>
              {state.exceptionModal && (
                <ExceptionModal
                  parent={state}
                  setParent={setState}
                  data={state.exceptionModal}
                  setLoader={setLoader}
                  timetable={timetable}
                  exceptionType={state.exceptionType}
                />
              )}
            </Box>
            <Box sx={{ display: "flex", marginBottom: "10px" }}>
              <ColorButton
                sx={{ flex: 1 }}
                onClick={() => setState({ exceptionModal: {}, exceptionType: 'single' })}
              >
                Nova Exceção
              </ColorButton>
                <ColorButton
                sx={{ flex: 1 }}
                onClick={() => setState({ exceptionModal: {}, exceptionType: 'multiple' })}
              >
                Adicionar Multiplas Exceções
              </ColorButton>
            </Box>

            <DataGrid
              rows={state.exceptions}
              columns={columns(state, setState)}
              density='compact'
              slots={{ toolbar: GridToolbar }}
              fullWidth
            />
          </Box>
        </>
      }
      />
    </>
  );
};

export default ExceptionList;
