import React, {useState, useEffect, createContext} from "react";
import { useNavigate } from "react-router-dom";

import {api, createSession} from "../services/api";

import { resetPermissions } from "../components/Utils/groups";

import jwt_decode from "jwt-decode";
import toast from "react-hot-toast";



export const AuthContext = createContext();

export const getSession = () => {
    return JSON.parse(sessionStorage.getItem('user'))
}


export const AuthProvider = ({children}) => {
    const navigate = useNavigate();
    
    const [user,setUser] = useState(null);
    const [loading,setLoading] = useState(true);

    useEffect(() => {
        const recoveredUser = sessionStorage.getItem('user')
        const token = sessionStorage.getItem('token')

        if(recoveredUser && token){
            setUser(JSON.parse(recoveredUser));
            api.defaults.headers.Authorization = `Bearer ${token}`
            resetPermissions()
        }

        setLoading(false);

    }, []);

    const login =  async (username,password) => {

        
        const response = await createSession(username,password)

        if (response.status !== 200){
            return toast.error('Usuário ou senha inválidos')
        }

        const token = response.data.access

        const decode = jwt_decode(token);

        const user_id = decode['user_id']
        
        const objLoggedUser = {
            "username":username,
            "id":user_id,
        }

        const loggedUser = objLoggedUser

        sessionStorage.setItem("email", JSON.stringify(loggedUser));
        sessionStorage.setItem("user", JSON.stringify(decode?.user));
        sessionStorage.setItem("token",token)

        api.defaults.headers.Authorization = `Bearer ${token}`
       
        resetPermissions()
        setUser(loggedUser);
        navigate("/home");
       
        
    };

    const logout = () => {
        setUser(null)
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('client');
        sessionStorage.removeItem('email');
        api.defaults.headers.Authorization = null;
        navigate("/login")
    };



    return (
        <AuthContext.Provider
            value={{authenticated: !!user, user, loading, login, logout}}>
                {children}
        </AuthContext.Provider>
    )
};

