import React, { useState } from 'react'
import ColorButton from '../Buttons/ColorButton'
import { DataGrid } from '@mui/x-data-grid'
import { Box, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import TeamsModal from './teams-dialog'
import ConfirmationDialog from '../Utils/ConfirmationDialog'

const columns = (setShowModal, setDeleteModal) => {
    return ([
        {
            field: 'name',
            headerName: 'Nome',
            flex: 1
        },
        {
            field: 'categories',
            headerName: 'Categorias',
            flex: 1,
            valueGetter: ({ value }) => {
                return value?.map(each => each.name).join(', ')
            }
        },
        {
            field: 'actions',
            headerName: 'Ações',
            renderCell: (props) => {
                return (
                    <Box >
                        <IconButton onClick={() => setShowModal(props.row)}><EditIcon /></IconButton>
                        <IconButton onClick={() => setDeleteModal(props.row)}><DeleteIcon /></IconButton>
                    </Box>
                )
            }
        }
    ])
}

//const teams = Array(100).fill(1).map((each, index) => ({idx:index+1}))

const EditTeams = ({ parent, setParent }) => {

    const [showModal, setShowModal] = useState()
    const [deleteModal, setDeleteModal] = useState()
    
    return (
        <>
            {showModal && <TeamsModal data={showModal} handleClose={() => setShowModal(false)} parent={parent} setParent={setParent} />}
            {deleteModal && <ConfirmationDialog
                content={`Deseja deletar o time ${deleteModal.name}?`}
                handleClose={() => setDeleteModal()}
                onConfirm={() => {
                    setParent({ teams: parent.teams.filter(each => each !== deleteModal) })
                    setDeleteModal()
                }} />}
            <Box sx={{display:'flex', gap:'1rem'}}>
                <ColorButton sx={{flex:1}} className='buttonstyle' onClick={() => setShowModal({})}>Novo Time</ColorButton>
                <ColorButton sx={{flex:1}} className='buttonstyle' onClick={() => setShowModal({'multiple': true})}>Adicionar multiplos times</ColorButton>
            </Box>
            <DataGrid
                rows={parent.teams}
                columns={columns(setShowModal, setDeleteModal)}
                pagination
                getRowId={(each) => each.idx}
                density='compact'
                hideFooter={true}
            />
        </>
    )

}

export default EditTeams