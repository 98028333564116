import React, { useContext, useReducer, useCallback, useEffect, useState, createContext } from 'react'
import { TextField, Box, Divider, InputLabel, Tooltip } from '@mui/material'
import { defaultReducer } from '../Utils/reducers'
import DefButton from '../Buttons/defbutton'
import ReplyIcon from '@mui/icons-material/Reply';
import ColorButton from '../Buttons/ColorButton';
import EditDisponibility from './edit-disponibility';
import EditTeams from './edit-teams';
import { externalComponent } from '../AppRoutes';
import { api } from '../../services/api';
import toast from 'react-hot-toast';

export const schoolContext = createContext()

export const getCategories = async () => {
    const res = await api.get('/categories/')
    if (res.status === 200)
        return res.data.results
    return false
}

const validateSchool = (state) => {
    const answer = []

    if (state.name === '')
        answer.push(<Box>Adicione o nome da escola.</Box>)

    if (answer.length === 0)
        return ''

    return answer
}

let initialState = null

const EditSchool = ({ data, parent, setParent, goBack }) => {

    const { isMobile, setLoader } = useContext(externalComponent)

    const [state, setState] = useReducer(defaultReducer, {
        name: '',
        adress: '',
        disponibilities: [],
        teams: []
    })

    const [categories, setCategories] = useState([])

    const initialize = useCallback(async () => {

        const categories = await getCategories()

        setCategories(categories)

        if (data.id){
            data.teams.map(each => each.idx = each.id)
            setState(data)
        }

        initialState = data

        return (()=>{
            initialState = null
        })

    }, [data])

    useEffect(() => {
        initialize()
    }, [initialize])

    const submit = useCallback(async (state, setState) => {
        setLoader(true)
        let res = null
        if (state.id)
            res = await api.patch(`/school/${state.id}/`, state)
        else
            res = await api.post("/school/", state)
        if ([200, 201].includes(res.status)) {
            const new_data = res.data
            new_data.teams.map(each => each.idx = each.id)
            setState(new_data)
            toast.success("Escola salva com sucesso")
        }
        setLoader(false)
    }, [setLoader])

    const validate_school = validateSchool(state)

    return (
        <schoolContext.Provider value={{ categories, setCategories }}>
            <Box className='school-header'>
                <Box className='school-name config-width'>
                    <TextField
                        label='Escola'
                        value={state.name}
                        onChange={(e) => setState({ name: e.target.value })}
                        size='small'
                        placeholder='Nome da escola'
                        sx={{ width: "100%" }}
                    />
                </Box>
                <Box className={`school-name ${isMobile ? 'externalize' : ''}`}>
                    <Tooltip title="Cancelar">
                        <span>
                            <ColorButton
                                onClick={() => setState(initialState)}
                                className='buttonstyle'>
                                Cancelar
                            </ColorButton>
                        </span>
                    </Tooltip>
                    <Tooltip title={validate_school}>
                        <span>
                            <ColorButton
                                disabled={validate_school}
                                onClick={() => submit(state, setState)}
                                className='buttonstyle'
                                >
                                Salvar
                            </ColorButton>
                        </span>
                    </Tooltip>
                    <DefButton
                        onClick={goBack}
                    >
                        <ReplyIcon />
                    </DefButton>
                </Box>
            </Box>
            <Box class='school-data'>
                <Box className='school-adress'>
                    <TextField
                        label='Endereço'
                        placeholder='Endereço da Escola'
                        size='small'
                        multiline={true}
                        maxRows={4}
                        value={state.adress}
                        onChange={e => setState({ adress: e.target.value })}
                        inputProps={{
                            sx: {
                                overflow: 'none',
                                textWrap: 'wrap',
                            }
                        }}
                    />
                </Box>
            </Box>
            <Divider />
            <Box className='menu-divider'>
                <Box className='school-container' sx={ isMobile && { minWidth: '100%'}}>
                    <Box className='school-container-container'>
                        <InputLabel className='school-container-label'>Disponibilidade</InputLabel>
                        <EditDisponibility parent={state} setParent={setState} />
                    </Box>
                </Box>
                <Box className='school-container' sx={ isMobile && { minWidth: '100%'}}>
                    <Box className='school-container-container'>
                        <InputLabel className='school-container-label'>Times</InputLabel>
                        <EditTeams parent={state} setParent={setState} />
                    </Box>
                </Box>
            </Box>
        </schoolContext.Provider >
    )
}

export default EditSchool