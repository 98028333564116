import React, { useCallback, useContext, useEffect, useReducer, useState } from 'react'
import { defaultReducer } from '../../Utils/reducers'
import { api } from '../../../services/api'
import { getSession } from '../../../contexts/auth'
import { toast } from 'react-hot-toast'
import { Box, TextField } from '@mui/material'
import { PhoneInput } from '../../Utils/MaskedInput'

import './styles.css'
import ColorButton from '../../Buttons/ColorButton'
import apptheme from '../../theme/default'
import SaveIcon from '@mui/icons-material/Save';
import { externalComponent } from '../../AppRoutes'
import PasswordIcon from '@mui/icons-material/Password';
import ChangePassword from './change-password'

const Profile = () => {

    const { setLoader } = useContext(externalComponent)

    const [showPasswordDialog, setShowPasswordDialog] = useState(false)

    const [state, setState] = useReducer(defaultReducer, {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        adress: [],
    })
    const user = getSession()

    // const addAdress = (value) => {
    //     setState({ ...value })
    // }


    const getProfile = useCallback(async () => {
        setLoader(true)
        const res = await api.get(`/users/${user.id}/`)
        setLoader(false)
        if (res.status === 200) {
            setState(res.data)
            return
        }
        toast.error('Ocorreu um erro ao gerar os dados')

    }, [user.id, setLoader])


    useEffect(() => {
        getProfile()
    }, [getProfile])

    const saveData = useCallback(async (data) => {
        setLoader(true)
        const res = await api.patch(`/users/${user.id}/`, data)
        setLoader(false)
        if (res.status === 200) {
            setState(res.data)
            toast.success('Dados salvos com sucesso')
            return
        }

        toast.error('Ocorreu um erro ao gerar os dados')

    }, [user.id, setLoader])

    const changePassword = async(newPassword) => {
        const res = await api.post(`/users/change/`, {password:newPassword})
        if (res.status === 200){
            toast.success('Senha alterada com sucesso!')
            setShowPasswordDialog(false)
            return
        }
        else if (res.status === 400){
            toast.error(res.data.join('\n'))
            return
        }
        toast.error('A senha não foi alterada pois ocorreu um erro.')
    }

    return (
        <>
            {showPasswordDialog && <ChangePassword ChangePassword={changePassword} handleClose={() => setShowPasswordDialog(false)}/>}
            {state.id &&
                <>
                    <Box sx={{display:'flex', gap:'1rem'}}>
                        <ColorButton
                            className='profile-save-button'
                            onClick={() => saveData(state)}
>
                            <Box className='flexbox'><SaveIcon /> Salvar</Box>
                        </ColorButton>
                        <ColorButton
                            className='profile-save-button'
                            onClick={() => setShowPasswordDialog(true)}
>
                            <Box className='flexbox'><PasswordIcon /> Trocar senha</Box>
                        </ColorButton>
                    </Box>
                    <Box className='profile-overflow'>
                        <Box className='item-content-modal' >
                            <TextField
                                id="clientname"
                                type="text"
                                size='small'
                                sx={{ backgroundColor: 'white' }}
                                label="Nome do usuário"
                                value={state.first_name}
                                onChange={(e) => { setState({ 'first_name': e.target.value }) }}
                                inputProps={{
                                    maxLength: 50
                                }}
                            />

                            <TextField
                                id="clientlastname"
                                type="text"
                                sx={{ backgroundColor: 'white' }}
                                label="Sobrenome"
                                size='small'
                                value={state.last_name}
                                onChange={(e) => { setState({ 'last_name': e.target.value }) }}
                                inputProps={{
                                    maxLength: 50
                                }}
                            />


                            <TextField
                                id="clientphone"
                                type="text"
                                size='small'
                                sx={{ backgroundColor: 'white' }}
                                label="Telefone"
                                value={state.phone_number}
                                onChange={(e) => { setState({ 'phone_number': e.target.value }) }}
                                InputProps={{
                                    inputComponent: PhoneInput
                                }}
                            />

                            <TextField
                                id="clientemail"
                                type="email"
                                size='small'
                                sx={{ backgroundColor: 'white' }}
                                label="E-mail"
                                disabled
                                value={state.email}
                                onChange={(e) => { setState({ 'email': e.target.value }) }}
                                inputProps={{
                                    maxLength: 100
                                }}
                            />

                            <TextField
                                id="groups"
                                type="text"
                                sx={{ backgroundColor: 'white' }}
                                label="Grupos"
                                disabled
                                size='small'
                                defaultValue={user?.groups?.list.map(each => each.name)?.join(', ')}
                                fullWidth={true}
                                inputProps={{
                                    maxLength: 100
                                }}
                            />
                        </Box>
                    </Box>
                </>}
        </>
    )
}

export default Profile