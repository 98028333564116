import React from 'react'
import DefaultModal from "./DefaultModal";
import ColorButton from '../Buttons/ColorButton';

const ConfirmationDialog = ({content, handleClose, onConfirm}) => {
    return (
        <DefaultModal 
            title='Confirmação'
            content={content}
            handleClose={handleClose}
            action={<ColorButton onClick={onConfirm} >Sim</ColorButton>}
        />
    )
}

export default ConfirmationDialog