import React, { useContext, useReducer, useCallback, useEffect, useState, createContext } from 'react'
import { TextField, Box, Divider, Tooltip } from '@mui/material'
import { defaultReducer } from '../Utils/reducers'
import DefButton from '../Buttons/defbutton'
import ReplyIcon from '@mui/icons-material/Reply';
import ColorButton from '../Buttons/ColorButton';
import { externalComponent } from '../AppRoutes';
import { api } from '../../services/api';
import toast from 'react-hot-toast';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ptBR from 'dayjs/locale/pt-br';
import { ptBR as localePTBR } from '@mui/x-date-pickers/locales';
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import TeamsTable from './teams-table';
import TimeTable from './timetable';
import ChampionshipTable from './championship-table';

export const ChampionshipContext = createContext()

export const calc_date = (value) => {
    if (!value) return ''
    return dayjs(value)
}

const validateChampionship = (state) => {
    const answer = []

    if (state.name === '')
        answer.push(<Box>Adicione o nome do campeonato.</Box>)
    if (state.init_date === '')
        answer.push(<Box>Adicione a data de início.</Box>)
    if (state.end_date === '')
        answer.push(<Box>Adicione a data de fim.</Box>)
    if (state.teams.length === 0)
        answer.push(<Box>Adicione os times.</Box>)

    if (answer.length === 0)
        return ''

    return answer
}

const championshipTabs = (parent, setParent) => ({
    timetable: { name: "Tabela de horas", component: (<TimeTable parent={parent} setParent={setParent} />) },
    cuptable: { name: "Tabela da copa", component: (<ChampionshipTable parent={parent} setParent={setParent}/>) }
})

let initialState = null

const EditChampionship = ({ data, parent, setParent, goBack }) => {

    const { isMobile, setLoader } = useContext(externalComponent)

    const [state, setState] = useReducer(defaultReducer, {
        name: '',
        init_date: '',
        end_date: '',
        teams: [],
        winner: '',
        timetable: null,
        cuptable: null,
        initiated: false,
        results: null,
        ...data
    })

    const [selectedTab, setSelectedTab] = useState(!data.initiated ?
         Object.keys(championshipTabs(state, setState))[0] :
         Object.keys(championshipTabs(state, setState))[1] )

    const [categories, setCategories] = useState([])

    const initialize = useCallback(async () => {
        if (data.id){
            setLoader(true)
            const res = await api.get(`/championship/${data.id}/`)
            setLoader(false)
            if (res.status === 200){
                initialState = JSON.stringify(res.data)
                setState(res.data)
                return
            } else{
                toast.error('Falha ao carregar os dados da tabel.')
            }

        }
            
        initialState = JSON.stringify(data)
        return ( () => {
            initialState = null
        })
    }, [])

    useEffect(() => {
        initialize()
    }, [initialize])

    const submit = useCallback(async (state, setState) => {
        setLoader(true)

        let res = null
        if (state.id)
            res = await api.patch(`/championship/${state.id}/`, state)
        else
            res = await api.post("/championship/", state)
        if ([200, 201].includes(res.status)) {
            const new_data = res.data
            new_data.teams.map(each => each.idx = each.id)
            initialState = JSON.stringify(new_data)
            setState(new_data)
            toast.success("Campeonato salvo com sucesso")
            
        }
        setLoader(false)
    }, [setLoader])

    const validate_championship = validateChampionship(state)
    const championshiptabs = championshipTabs(state, setState)

    return (
        <ChampionshipContext.Provider value={{ categories, setCategories }}>
            <Box className='school-header'>
                <Box className='school-name config-width'>
                    <TextField
                        label='Campeonato'
                        value={state.name}
                        onChange={(e) => setState({ name: e.target.value })}
                        size='small'
                        placeholder='Nome do campeonato'
                        sx={{ width: "100%", minWidth: '20rem' }}
                    />
                    <Box className='default-row-spacebetween'>
                        <LocalizationProvider sx={{ justifyContent: 'space-between' }} dateAdapter={AdapterDayjs} adapterLocale={ptBR}
                            localeText={localePTBR.components.MuiLocalizationProvider.defaultProps.localeText}
                        >
                            <>
                                <DatePicker
                                    sx={{ width: '100%', minWidth: '10rem' }}
                                    label={'Data de início'}
                                    slotProps={{ textField: { size: 'small' } }}
                                    value={calc_date(state.init_date)}
                                    format='DD/MM/YYYY'
                                    onChange={e => { setState({ init_date: e.format('YYYY-MM-DD') })}}
                                    ampm={false}
                                />
                                <DatePicker
                                    sx={{ width: '100%', minWidth: '10rem' }}
                                    label={'Data de fim'}
                                    slotProps={{ textField: { size: 'small' } }}
                                    value={calc_date(state.end_date)}
                                    format='DD/MM/YYYY'
                                    onChange={e => setState({ end_date: e.format('YYYY-MM-DD') })}
                                    ampm={false}
                                />
                            </>
                        </LocalizationProvider>
                    </Box>
                </Box>

                <Box className={`school-name ${isMobile ? 'externalize' : ''}`}>
                    <Tooltip title="Cancelar">
                        <span>
                            <ColorButton
                                onClick={() => {setState(JSON.parse(initialState))}}
                                className='buttonstyle'>
                                Cancelar
                            </ColorButton>
                        </span>
                    </Tooltip>
                    <Tooltip title={validate_championship}>
                        <span>
                            <ColorButton
                                disabled={validate_championship}
                                onClick={() => submit(state, setState)}
                                className='buttonstyle'>
                                Salvar
                            </ColorButton>
                        </span>
                    </Tooltip>
                    <DefButton
                        onClick={goBack}
                    >
                        <ReplyIcon />
                    </DefButton>
                </Box>
            </Box>
            <Divider />
            <Box className="cupselectbutton">
                {Object.entries(championshiptabs).sort(() => state.initiated ? -1 : 1).map(([key, value]) => (
                    <ColorButton
                        reverse={key === selectedTab ? false : true}
                        onClick={() => setSelectedTab(key)}>{value.name}
                    </ColorButton>
                ))}
            </Box>
            <Box sx={{height:'100%', overflow:'hidden'}}>
                {championshiptabs[selectedTab].component}
            </Box>
        </ChampionshipContext.Provider >
    )
}

export default EditChampionship