
import React, { useReducer } from 'react'
import DefaultModal from '../Utils/DefaultModal'
import ColorButton from '../Buttons/ColorButton'
import { defaultReducer } from '../Utils/reducers'
import { TextField, Tooltip } from '@mui/material'
import { Box } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ptBR from 'dayjs/locale/pt-br';
import { ptBR as localePTBR } from '@mui/x-date-pickers/locales';
import { TimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'

export const weekdays = {
    0: 'Domingo',
    1: 'Segunda',
    2: 'Terça',
    3: 'Quarta',
    4: 'Quinta',
    5: 'Sexta',
    6: 'Sábado',
}

export const calc_time = (value) => {
    if (!value) return ''
    const [hour, minutes] = value.split(':')
    return dayjs().hour(hour).minute(minutes)
}

const submit = (state, handleClose, parent, setParent) => {

    if (state.idx) {
        const old_state = parent.find(each => each.idx === state.idx)
        Object.assign(old_state, state)
    }
    else
        setParent([...parent, { ...state, idx: Math.random() }])
    handleClose()

}

const validateDisponibility = (state) => {
    const answer = []

    if (state.timeini === '')
        answer.push(<Box>Adicione uma hora de início.</Box>)
    if (state.timeend === '')
        answer.push(<Box>Adicione uma hora de fim.</Box>)

    if (answer.length === 0)
        return ''

    return answer
}

const TimeTableDisponibilityModal = ({ data, handleClose, parent, setParent }) => {

    const [state, setState] = useReducer(defaultReducer, {
        name: '',
        timeini: '',
        timeend: '',
        ...data
    })

    const validate_disponibility = validateDisponibility(state)

    return (
        <>
            <DefaultModal
                title={`${data.id ? 'Editar' : 'Nova'} disponibilidade`}
                content={<Box className='default-column-spacing'>
                    <TextField
                        label='Descrição'
                        size='small'
                        fullWidth
                        value={state.name}
                        inputProps={{
                            maxLength: 255
                        }}
                        onChange={(e) => setState({ name: e.target.value })}
                    />
                    <Box className='default-row-spacebetween'>
                        <LocalizationProvider sx={{ justifyContent: 'space-between' }} dateAdapter={AdapterDayjs} adapterLocale={ptBR}
                            localeText={localePTBR.components.MuiLocalizationProvider.defaultProps.localeText}
                        >
                            <>
                                <TimePicker
                                    sx={{ width: '100%' }}
                                    label={'Hora de início'}
                                    slotProps={{ textField: { size: 'small' } }}
                                    value={calc_time(state.timeini)}
                                    onChange={e => setState({ timeini: e.format('HH:mm') })}
                                    ampm={false}
                                />
                                <TimePicker
                                    sx={{ width: '100%' }}
                                    label={'Hora de fim'}
                                    slotProps={{ textField: { size: 'small' } }}
                                    value={calc_time(state.timeend)}
                                    onChange={e => setState({ timeend: e.format('HH:mm') })}
                                    ampm={false}
                                />
                            </>
                        </LocalizationProvider>
                    </Box>
                </Box>}
                action={<>
                    <Tooltip title={validate_disponibility}>
                        <span>
                            <ColorButton
                                disabled={validate_disponibility}
                                onClick={() => submit(state, handleClose, parent, setParent)}>
                                Confirmar
                            </ColorButton>
                        </span>
                    </Tooltip>
                </>}
                handleClose={handleClose}
            />
        </>
    )
}

export default TimeTableDisponibilityModal