import React, { useReducer, useState, useContext } from "react";
import DefaultModal from "../Utils/DefaultModal";
import ColorButton from "../Buttons/ColorButton";
import { defaultReducer } from "../Utils/reducers";
import {
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/material";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import { api } from "../../services/api";
import { externalComponent } from "../AppRoutes";
import { getCategories, schoolContext } from "./edit-school";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationDialog from "../Utils/ConfirmationDialog";
import EditDisponibility from "./edit-disponibility";

export const saveCategory = async (name) => {
  const res = await api.post("/categories/", { name: name });
  if (res.status === 201) return res.data;
  return false;
};

export const removeCategory = async (id) => {
  const res = await api.delete(`/categories/${id}/`);
  if (res.status === 204) return true;
  return false;
};

export const CategoryModal = ({ handleClose, parent, setParent }) => {
  const { setLoader } = useContext(externalComponent);
  const { setCategories } = useContext(schoolContext);
  const [name, setName] = useState("");

  const submit = async () => {
    setLoader(true);
    if (await saveCategory(name)) {
      handleClose();
      const new_categories = await getCategories();
      if (new_categories) setCategories(new_categories);
    }
    setLoader(false);
  };

  return (
    <>
      <DefaultModal
        title="Adicionar Categoria"
        content={
          <TextField
            label="Nome"
            size="small"
            fullWidth
            value={name}
            inputProps={{
              maxLength: 255,
            }}
            onChange={(e) => setName(e.target.value)}
          />
        }
        action={
          <>
            <ColorButton onClick={submit}>Salvar</ColorButton>
          </>
        }
        handleClose={handleClose}
      ></DefaultModal>
    </>
  );
};

const submit = (state, parent, setParent, handleClose) => {
  if (state.multiple) {
    const states = [];
    state.categories.map((each) => {
      const { multiple, ...data } = state;
      states.push({
        ...data,
        categories: [each],
        idx: Math.random(),
      });
    });
    setParent({ teams: [...parent.teams, ...states] });
    handleClose();
    return;
  }

  if (state.idx) {
    const old_state = parent.teams.find((each) => each.idx === state.idx);
    Object.assign(old_state, state);
  } else
    setParent({ teams: [...parent.teams, { ...state, idx: Math.random() }] });
  handleClose();
};

const validateTeams = (state) => {
  const answer = [];

  if (state.name === "") answer.push(<Box>Adicione o nome do time.</Box>);
  if (state.disponibilities.length === 0)
    answer.push(<Box>Adicione uma disponibilidade.</Box>);
  if (state.categories.length === 0)
    answer.push(<Box>Adicione uma categoria.</Box>);

  if (answer.length === 0) return "";

  return answer;
};

const TeamsModal = ({ data, handleClose, parent, setParent }) => {
  const { setLoader } = useContext(externalComponent);
  const { categories, setCategories } = useContext(schoolContext);
  const [state, setState] = useReducer(defaultReducer, {
    categories: [],
    disponibilities: [],
    name: "",
    ...data,
  });

  const [showAddCategory, setShowAddCategory] = useState();
  const [deleteCategory, setShowDeleteCategory] = useState();

  const submitDelete = async () => {
    setLoader(true);
    if (await removeCategory(deleteCategory.id)) {
      const new_categories = await getCategories();
      if (new_categories) setCategories(new_categories);
    }
    setShowDeleteCategory();
    setLoader(false);
  };

  const validate_teams = validateTeams(state);

  return (
    <>
      {showAddCategory && (
        <CategoryModal
          handleClose={() => setShowAddCategory()}
          parent={parent}
          setParent={setParent}
        />
      )}
      {deleteCategory && (
        <ConfirmationDialog
          content={`Deseja deletar a categoria ${deleteCategory.name}?`}
          handleClose={() => setShowDeleteCategory()}
          onConfirm={submitDelete}
        />
      )}
      <DefaultModal
        dialogProps={{ maxWidth: "md" }}
        title={`${data.id ? "Editar" : "Novo"}${state.multiple ? "s" : ""} ${
          state.multiple ? "multiplos" : ""
        } Time${state.multiple ? "s" : ""}`}
        content={
          <Box className="default-column-spacing" sx={{ height: "30rem" }}>
            <TextField
              label="Nome"
              size="small"
              fullWidth
              value={state.name}
              inputProps={{
                maxLength: 255,
              }}
              onChange={(e) => setState({ name: e.target.value })}
            />
            <FormControl size="small">
              <InputLabel id="categorias-input-label">Categoria</InputLabel>
              <Select
                multiple={data.multiple}
                sx={{ paddingLeft: "0.1rem" }}
                value={state.categories}
                label="Categoria"
                labelId="categorias-input-label"
                startAdornment={
                  <IconButton>
                    <AddCircleOutline
                      onClick={() => {
                        setShowAddCategory(true);
                      }}
                    />
                  </IconButton>
                }
                placeholder="Categoria"
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        deleteIcon={
                          <CancelIcon
                            onMouseDown={(event) => event.stopPropagation()}
                          />
                        }
                        key={value}
                        label={value.name}
                        onDelete={(e) => {
                          e.preventDefault();
                          setState({
                            categories: selected.filter(
                              (each) => each.id !== value.id
                            ),
                          });
                        }}
                      />
                    ))}
                  </Box>
                )}
                onChange={(e) =>
                  data.multiple
                    ? setState({ categories: e.target.value })
                    : setState({ categories: [e.target.value] })
                }
              >
                {categories
                  ?.filter(
                    (each) =>
                      !state.categories.map((each) => each.id).includes(each.id)
                  )
                  .map((each) => (
                    <MenuItem
                      className="default-row-spacebetween"
                      sx={{ justifyContent: "space-between" }}
                      value={each}
                    >
                      <Box>{each.name}</Box>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowDeleteCategory(each);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <EditDisponibility parent={state} setParent={setState} />
          </Box>
        }
        action={
          <>
            <Tooltip title={validate_teams}>
              <span>
                <ColorButton
                  disabled={validate_teams}
                  onClick={() => submit(state, parent, setParent, handleClose)}
                >
                  Confirmar
                </ColorButton>
              </span>
            </Tooltip>
          </>
        }
        handleClose={handleClose}
      />
    </>
  );
};

export default TeamsModal;
