import React, { useState } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { Box } from '@mui/material'
import DefaultModal from '../Utils/DefaultModal'
import ColorButton from '../Buttons/ColorButton'
import TimeTableDisp from './timetable-disp'
import CustomToolbar from './custom-toolbar'

const dayweekdepara = {
    seg: 'Segunda',
    ter: 'Terça',
    qua: 'Quarta',
    qui: 'Quinta',
    sex: 'Sexta',
    sab: 'Sábado',
    dom: 'Domingo'
}

const EditableComponent = ({ data, parent, setParent, disabled }) => {
    const [showModal, setShowModal] = useState()

    return (
        <>
            {showModal && <>

                <DefaultModal
                    title={`${data.row.name} - ${dayweekdepara[data.field]}`}
                    handleClose={() => setShowModal()}
                    content={<TimeTableDisp data={data.value} submit={(newData) => { data.value = newData }} disabled={disabled} />}
                    action={<>
                        {!disabled && <ColorButton onClick={() => {
                            const element = parent[data.row.type].find(each => each.id === data.id)
                            element[data.field] = data.value
                            setParent({ ...parent })
                            setShowModal()
                        }}>Salvar</ColorButton>}
                    </>}
                />
            </>
            }
            {data.value.length > 0 ?
                <Box className='greenCell cell' onClick={() => setShowModal(data)}></Box>
                :
                <Box className='redCell cell' onClick={() => setShowModal(data)}></Box>}
        </>
    )
}

const columns = (parent, setParent, disabled) => {

    const editableComponent = (params) => {
        return (<EditableComponent data={params} parent={parent} setParent={setParent} disabled={disabled} />)
    }

    return [
        {
            field: 'group',
            headerName: 'Chave',
            flex: .2,
            editable: !disabled,
            renderCell: ({ value }) =>
                <Box sx={{ padding: '0px 10px' }}>{value}</Box>
        },
        {
            field: 'name',
            headerName: 'Time',
            flex: .5,
            renderCell: ({ value }) =>
                <Box sx={{ padding: '0px 10px' }}>{value}</Box>
        },
        {
            field: 'categories',
            headerName: 'Categoria',
            flex: .5,
            valueGetter: ({ value }) => {
                return value?.map(each => each.name).join(', ')
            }
        },
        {
            field: 'seg',
            headerName: '2a',
            flex: .1,
            sortable: false,
            renderCell: editableComponent
        },
        {
            field: 'ter',
            headerName: '3a',
            flex: .1,
            sortable: false,
            renderCell: editableComponent
        },
        {
            field: 'qua',
            headerName: '4a',
            flex: .1,
            sortable: false,
            renderCell: editableComponent
        },
        {
            field: 'qui',
            headerName: '5a',
            flex: .1,
            sortable: false,
            renderCell: editableComponent
        },
        {
            field: 'sex',
            headerName: '6a',
            flex: .1,
            sortable: false,
            renderCell: editableComponent
        },
        {
            field: 'sab',
            headerName: 'Sb',
            flex: .1,
            sortable: false,
            renderCell: editableComponent
        },
        {
            field: 'dom',
            headerName: 'Dm',
            flex: .1,
            sortable: false,
            renderCell: editableComponent
        }
    ]
}



const TimeTableTeams = ({ parent, setParent, disabled, extraComponent }) => {

    return (
        <>
            <DataGrid
                rows={parent.teams}
                columns={columns(parent, setParent, disabled)}
                density='compact'
                slots={{ toolbar: () =>CustomToolbar(extraComponent) }}
                disableColumnMenu
                processRowUpdate={(updatedRow, originalRow) => {
                    const object = parent.teams.find(row => row.id === originalRow.id)
                    Object.assign(object, updatedRow)
                    setParent(parent)
                    return updatedRow
                }
                }
                onProcessRowUpdateError={(e) => { console.error(e) }}
                sx={{
                    '.MuiDataGrid-cell': {
                        padding: '0px 1px'
                    }
                }}
            />
        </>
    )
}

export default TimeTableTeams