import React, { useEffect, useReducer, useCallback, useContext } from 'react'

import { DataGrid, GridToolbar  } from '@mui/x-data-grid';
import SearchInput from '../Utils/SearchInput'
import { defaultReducer } from '../Utils/reducers'
import { api } from '../../services/api'
import { Box, IconButton } from '@mui/material';
import ColorButton from '../Buttons/ColorButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import './styles.css'
import { externalComponent } from '../AppRoutes';
import ConfirmationDialog from '../Utils/ConfirmationDialog';
import toast from 'react-hot-toast';
import EditChampionship from './edit-championship';
import dayjs from 'dayjs';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const columns = (setState) =>
    [
        {
            field: 'name',
            headerName: 'Escola',
            flex: 1
        },
        {
            field: 'init_date',
            headerName: 'Início',
            flex: 1,
            valueGetter: ({value}) => dayjs(value).format('DD/MM/YYYY')
        },
        {
            field: 'end_date',
            headerName: 'Fim',
            flex: 1,
            valueGetter: ({value}) => dayjs(value).format('DD/MM/YYYY')
        },
        {
            field: 'winner',
            headerName: 'Vencedor',
            flex: 1,
        },
        {
            field: 'categories',
            headerName: 'Categorias',
            flex: 1,
            valueGetter: ({row}) => Array.from(new Set(row.teams.map(each => each.categories.map(category => category.name)).flat(1))).join(', ') 
        },
        {
            field: 'teams',
            headerName: 'Times',
            flex: 1,
            valueGetter: ({value}) => value?.map(each=> each.name).join(', ')
        },
        {
            field: 'initiated',
            headerName: 'Iniciado',
            flex: 1,
            renderCell: ({value}) => {
                return (
                    value ? 
                       <CheckCircleIcon sx={{color:'green'}} />
                        :
                       <HighlightOffIcon sx={{color:'red'}} />
                )
            }
        },
        {
            field: 'actions',
            headerName: 'Ações',
            renderCell: (props) => {
                return (
                    <Box >
                        <IconButton onClick={() => { setState({ renderRow: props.row }) }}><EditIcon /></IconButton>
                        <IconButton onClick={() => { setState({ deleteRow: props.row }) }}><DeleteIcon /></IconButton>
                    </Box>
                )
            }
        }
    ]

const Championship = () => {

    const { setLoader } = useContext(externalComponent)
    const [state, setState] = useReducer(defaultReducer, {
        championshipList: [],
        renderRow: null,
        deleteRow: false
    })

    const initialize = useCallback(async () => {
        setLoader(true)
        const res = await api.get('/championship/')
        if (res.status === 200) {
            setState({ championshipList: res.data.results })
        }
        setLoader(false)
    }, [setLoader])

    useEffect(() => {
        initialize()
    }, [initialize])

    const confirmDelete = async () => {
        const res = await api.delete(`/championship/${state.deleteRow.id}/`)
        if (res.status === 204) {
            toast.success('Campeonato deletada com sucesso.')
            setState({ deleteRow: null })
        }
        initialize()
    }

    return (<>
        {state.deleteRow &&
            <ConfirmationDialog
                content={`Deseja deletar ${state.deleteRow.name}?`}
                handleClose={() => setState({ deleteRow: null })}
                onConfirm={confirmDelete}
            />
        }

        {state.renderRow ?
            <EditChampionship data={state.renderRow} parent={state} setParent={setState} goBack={() => { setState({ renderRow: null }); initialize() }} />
            :
            <>
                <Box className='divide-flex'>
                    <ColorButton className='buttonstyle' onClick={() => setState({ renderRow: {} })}>Novo campeonato</ColorButton>
                    <SearchInput />
                </Box>
                <DataGrid
                    rows={state.championshipList}
                    columns={columns(setState)}
                    pagination
                    density='compact'
                    slots={{ toolbar: GridToolbar }}
                />
            </>
        }
    </>)
}

export default Championship
