import React, { useState } from 'react'
import ColorButton from '../Buttons/ColorButton'
import { DataGrid } from '@mui/x-data-grid'
import { Box, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DisponibilityModal from './disponibility-dialog'
import ConfirmationDialog from '../Utils/ConfirmationDialog'
import { weekdays } from './disponibility-dialog'

const columns = (setShowModal, setDeleteModal) => {
    return ([
        {
            field: 'name',
            headerName: 'Descrição',
            flex: 1
        },
        {
            field: 'initDate',
            headerName: 'Início',
            flex: 1,
            valueGetter: (params) => {
                return `${weekdays[params.row.weekdayini]} - ${params.row.timeini}`
            }
        },
        {
            field: 'endDate',
            headerName: 'Fim',
            flex: 1,
            valueGetter: (params) => {
                return `${weekdays[params.row.weekdayend]} - ${params.row.timeend}`
            }
        },
        {
            field: 'actions',
            headerName: 'Ações',
            renderCell: (props) => {
                return (
                    <Box >
                        <IconButton onClick={() => setShowModal(props.row)}><EditIcon /></IconButton>
                        <IconButton onClick={() => setDeleteModal(props.row)}><DeleteIcon /></IconButton>
                    </Box>
                )
            }
        }
    ])
}

//const disponibilities = Array(100).fill(1).map((each, index) => ({idx:index+1}))

const EditDisponibility = ({ parent, setParent }) => {

    const [showModal, setShowModal] = useState()
    const [deleteModal, setDeleteModal] = useState()

    return (
        <>
            {showModal && <DisponibilityModal data={showModal} handleClose={() => setShowModal(false)} parent={parent} setParent={setParent} />}
            {deleteModal && <ConfirmationDialog
                content={`Deseja deletar ${deleteModal.name}?`}
                handleClose={() => setDeleteModal()}
                onConfirm={() => {
                    setParent({ disponibilities: parent.disponibilities.filter(each => each !== deleteModal) })
                    setDeleteModal()
                }} />}
            <Box sx={{display:'flex', gap:'1rem'}}>
                <ColorButton sx={{flex:1}} className='buttonstyle' onClick={() => setShowModal({})}>Nova Disponibilidade</ColorButton>
                <ColorButton sx={{flex:1}} className='buttonstyle' onClick={() => setShowModal({ multiple: true})}>Adicionar multiplas disponibilidades</ColorButton>
            </Box>
            <DataGrid
                rows={parent.disponibilities}
                columns={columns(setShowModal, setDeleteModal)}
                pagination
                getRowId={(each) => each.idx}
                density='compact'
                hideFooter={true}
            />
        </>
    )

}

export default EditDisponibility