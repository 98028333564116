import React, { useState } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { Box, IconButton, Tooltip } from '@mui/material'
import DefaultModal from '../Utils/DefaultModal'
import ColorButton from '../Buttons/ColorButton'
import TimeTableDisp from './timetable-disp'
import DeleteIcon from '@mui/icons-material/Delete'
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import CustomToolbar from './custom-toolbar'

const dayweekdepara = {
    seg: 'Segunda',
    ter: 'Terça',
    qua: 'Quarta',
    qui: 'Quinta',
    sex: 'Sexta',
    sab: 'Sábado',
    dom: 'Domingo'
}

const alterData = (data, parent, setParent) => {
    const element = parent[data.row.type].find(each => each.id === data.id)
    element[data.field] = data.value
    setParent({ ...parent })
}

const EditableComponent = ({ data, parent, setParent, disabled }) => {
    const [showModal, setShowModal] = useState()

    return (
        <>
            {showModal && <>

                <DefaultModal
                    title={`${data.row.name} - ${dayweekdepara[data.field]}`}
                    handleClose={() => setShowModal()}
                    content={<TimeTableDisp data={data.value} submit={(newData) => { data.value = newData }} disabled={disabled}/>}
                    action={<>
                        { !disabled && <ColorButton onClick={() => {
                            alterData(data, parent, setParent)
                            setShowModal()
                        }}>Salvar</ColorButton>}
                    </>}
                />
            </>
            }
            {data.value.length > 0 ?
                <Box className='greenCell cell' onClick={() => setShowModal(data)}></Box>
                :
                <Box className='redCell' onClick={() => setShowModal(data)}></Box>}
        </>
    )
}

const columns = (parent, setParent, disabled) => {

    const editableComponent = (params) => {
        return (<EditableComponent data={params} parent={parent} setParent={setParent} disabled={disabled}/>)
    }

    return [
        {
            field: 'name',
            headerName: 'Escola',
            flex: .5,
            renderCell: ({ value }) =>
                <Box sx={{ padding: '0px 10px' }}>{value}</Box>
        },
        {
            field: 'seg',
            headerName: '2a',
            flex: .1,
            sortable: false,
            renderCell: editableComponent
        },
        {
            field: 'ter',
            headerName: '3a',
            flex: .1,
            sortable: false,
            renderCell: editableComponent
        },
        {
            field: 'qua',
            headerName: '4a',
            flex: .1,
            sortable: false,
            renderCell: editableComponent
        },
        {
            field: 'qui',
            headerName: '5a',
            flex: .1,
            sortable: false,
            renderCell: editableComponent
        },
        {
            field: 'sex',
            headerName: '6a',
            flex: .1,
            sortable: false,
            renderCell: editableComponent
        },
        {
            field: 'sab',
            headerName: 'Sb',
            flex: .1,
            sortable: false,
            renderCell: editableComponent
        },
        {
            field: 'dom',
            headerName: 'Dm',
            flex: .1,
            sortable: false,
            renderCell: editableComponent
        },
        {
            field: 'priority',
            headerName: '',
            flex: .05,
            sortable: false,
            renderCell: (params) => (
                <><Tooltip title='Prioridade para abertura'>
                    <span>
                    {params.row.priority ?
                        <IconButton disabled={disabled} onClick={() => {
                            params.value = false
                            alterData(params, parent, setParent)
                        }
                        }> <StarIcon sx={{fill:'orange'}}/></IconButton>
                        :
                        <IconButton disabled={disabled} onClick={() => {
                            parent.schools.map(each => {each.priority = false})
                            params.value = true
                            alterData(params, parent, setParent)

                        }}> <StarBorderIcon /></IconButton>
                    }
                    </span>
                </Tooltip></>
            )
        },
        {
            field: 'rem',
            headerName: '',
            flex: .05,
            sortable: false,
            renderCell: ({ row }) => (
                <>{(row.exclude && !disabled) && <IconButton onClick={() => setParent({ schools: parent.schools.filter(each => each.id !== row.id) })}> <DeleteIcon /></IconButton>}</>
            )
        },

    ]
}

const TimeTableSchools = ({ parent, setParent, disabled, extraComponent }) => {

    return (
        <>
            <DataGrid
                rows={parent.schools}
                columns={columns(parent, setParent, disabled)}
                density='compact'
                disableColumnMenu
                slots={{ toolbar: () => CustomToolbar(extraComponent) }}
                sx={{
                    '.MuiDataGrid-cell': {
                        padding: '0px 1px'
                    }
                }}
            />
        </>
    )
}

export default TimeTableSchools