import { useState, useContext, createContext, useLayoutEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import "./styles.css";

import LoginComponent from "./Login/login";
import MenuComponent from "./Menu/menu";

import { Box } from "@mui/material";

import { AuthProvider, AuthContext } from "../contexts/auth";

import Home from "./Home/home";
import Loader from "./Loader/loader";

import Settings from "./Settings";
import School from "./School";
import Championship from "./Championship";
import { Toaster } from 'react-hot-toast'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ptBR } from '@mui/x-data-grid';

export const mobileWidth = '768'

export const externalComponent = createContext()

const theme = createTheme(
  {
    palette: {
      primary: { main: '#1976d2' },
    },
  },
  ptBR,
);

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
}

const PrivateRoute = ({ botmenu, permission, component }) => {
  const [width] = useWindowSize();
  const { authenticated, loading } = useContext(AuthContext);
  const [loader, setLoader] = useState(false)

  const isMobile = width <= mobileWidth

  if (loading) {
    return <Loader external show={true} />;
  }

  if (!authenticated) {
    return <Navigate to="/login" />;
  }


  return (
    <>
      <ThemeProvider theme={theme}>
        <externalComponent.Provider value={{ setLoader, isMobile }}>

          <Loader external show={loader} />
          <Box className="main-screen">
            <Box sx={{ flex: 9, overflow: 'hidden' }}>{component &&
              <Box className={'default_padding'}>
                {isMobile && <Box sx={{height:'3rem', padding:'1rem'}}></Box>}
                {component}
              </Box>
            }
            </Box>

            < >{botmenu && <MenuComponent />}</>
          </Box>
        </externalComponent.Provider>
      </ThemeProvider>
    </>

  );
};



const AppRoutes = () => {
  return (
    <Router>
      <Toaster position='bottom-center' />
      <AuthProvider>
        <Routes>
          <Route exact path="/login" element={<LoginComponent />} />
          <Route
            exact
            path="/home"
            element={
              <PrivateRoute botmenu permission={true} component={<Home />} />
            }
          />
          <Route
            exact
            path="/settings"
            element={
              <PrivateRoute
                botmenu
                permission={true}
                component={<Settings />}
              />
            }
          />
          <Route
            exact
            path="/schools"
            element={
              <PrivateRoute
                botmenu
                permission={true}
                component={<School />}
              />

            }
          />
          <Route
            exact
            path="/championship"
            element={
              <PrivateRoute
                botmenu
                permission={true}
                component={<Championship />}
              />

            }
          />
        </Routes>

      </AuthProvider>
    </Router>
  );
};

export default AppRoutes;
