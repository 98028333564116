import { Divider, Box, IconButton, Tooltip, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import React, { useReducer, useState } from 'react'
import DefaultModal from '../Utils/DefaultModal';
import { defaultReducer } from '../Utils/reducers';
import ColorButton from '../Buttons/ColorButton';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ptBR from 'dayjs/locale/pt-br';
import { ptBR as localePTBR } from '@mui/x-date-pickers/locales';
import dayjs from 'dayjs';

export const AddModal = ({ index, parent, setParent, generalData, data, setShowModal }) => {
    const [state, setState] = useReducer(defaultReducer, {
        matches: data.matches ? data.matches : [],
        actual_date: data.actual_date ? dayjs(data.actual_date) : '',
        school: data.school ? generalData.timetable.schools.find(each => each.id === data.school.id) : 0
    })

    return (
        <DefaultModal
            title={`${data?.school ? 'Editar' : 'Adicionar'} rodada ${index + 1}`}
            handleClose={() => setShowModal(null)}
            content={
                <Box className='default-row-spacebetween'>
                    <FormControl size='small' sx={{ width: '100%' }}>
                        <InputLabel id='place-input-label'>Local</InputLabel>
                        <Select
                            sx={{ paddingLeft: '0.1rem' }}
                            value={state.school}
                            label='Local'
                            labelId='place-input-label'
                            placeholder='Local'
                            onChange={(e) => setState({ school: e.target.value })}>
                            <MenuItem value={0}>Selecione *</MenuItem>
                            {generalData?.timetable?.schools.map((each) => (<MenuItem value={each}>{each.name}</MenuItem>))}
                        </Select>
                    </FormControl>
                    <LocalizationProvider sx={{ justifyContent: 'space-between' }} dateAdapter={AdapterDayjs} adapterLocale={ptBR}
                        localeText={localePTBR.components.MuiLocalizationProvider.defaultProps.localeText}
                    >
                        <DatePicker
                            sx={{ width: '100%', minWidth: '10rem' }}
                            label={'Data'}
                            slotProps={{ textField: { size: 'small' } }}
                            value={state.actual_date}
                            format='DD/MM/YYYY'
                            minDate={dayjs(generalData.init_date)}
                            maxDate={dayjs(generalData.end_date)}
                            onChange={e => { setState({ actual_date: e }) }}
                            ampm={false}
                        />
                    </LocalizationProvider>
                </Box>}
            action={<>
                <ColorButton
                    disabled={state.school === 0 || state.actual_date === ''}
                    onClick={() => {
                        parent.table.splice(index, 0, state)
                        setParent({ ...parent })
                        setShowModal()
                    }}>{data?.school ? 'Editar' : 'Adicionar'}</ColorButton>
            </>}
        />
    )

}

const AddRound = ({ generalData, setParent, parent, index, data = {} }) => {
    const [hover, setHover] = useState(false)
    const [showModal, setShowModal] = useState()
    const style = {
        width: !hover ? 0 : '2.5rem',
        padding: !hover ? 0 : '1rem',
        visibility: hover ? 'visible' : 'hidden'
    }

    return (
        <>
            {showModal &&
                <AddModal
                    index={index}
                    parent={parent}
                    setParent={setParent}
                    data={data}
                    generalData={generalData}
                    setShowModal={setShowModal}
                />
            }

            <Box onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} className='divider-half'>
                <Divider sx={{ flex: 1 }} />
                <Tooltip title='Nova rodada'><IconButton onClick={() => setShowModal(true)} sx={{ height: '2.5rem', ...style }}><AddCircleOutlineIcon /></IconButton></Tooltip>
                <Divider sx={{ flex: 1 }} />
            </Box>
        </>
    )
}

export default AddRound