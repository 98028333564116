
import { useState, useContext } from 'react';
import './menu.css'
import apptheme from '../theme/default';

//import CottageIcon from '@mui/icons-material/Cottage';
//import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import { Box, Button } from '@mui/material';
import Logout from '../Settings/Logout'
import { useLocation } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu';

import SchoolIcon from '@mui/icons-material/School';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { isAdmin } from '../Utils/groups'
import { externalComponent } from '../AppRoutes';
import SettingsIcon from '@mui/icons-material/Settings';



const menus = () => ({
  //home: { title: 'Início', icon: (<CottageIcon />), permissions: true },
  schools: { title: 'Escolas', icon: (<SchoolIcon />), permissions: isAdmin },
  championship: { title: 'Campeonato', icon: (<EmojiEventsIcon />), permissions: isAdmin },
  settings: { title: 'Configurações', icon: (<SettingsIcon />), permissions: isAdmin },

})

const exit = () => ({
  exit: { title: 'Sair', icon: (<LogoutIcon />), overthrow: { onClick: Logout }, permissions: true }
})



const MenuComponent = () => {
  const {isMobile} = useContext(externalComponent)
  const [showMenu, setShowmenu] = useState(false)

  const selectedTheme = {
    sx: {
      color: apptheme.secondaryColor,
      backgroundColor: `${apptheme.tertiaryColor} !important`
    },
    className: 'main-menu-button selected'
  }

  const menuTheme = {
    sx: { color: apptheme.secondaryColor },
    className: 'main-menu-button'
  }

  const decideTheme = (actualRoute) => {
    if (actualRoute === route.pathname)
      return { ...selectedTheme }
    return { ...menuTheme }
  }

  const route = useLocation()
  const navigate = useNavigate();



  return (
    <Box className={`${(isMobile || showMenu) ? 'overlay' : ''}`} sx={{height:'100%'}}>
    {(!isMobile || showMenu) ? 
    <Box className='lateral-menu' sx={{ backgroundColor: apptheme.primaryColor, height: "100%" }}>
      <Box className='inside-menu'>
        <Button
        onClick={() => navigate(`/home`)}
        children={<img src='/static/trophy.png' alt='Tabela da copa kids' />}
        >
        </Button>
        {Object.entries(menus()).map(([key, value]) =>
          <>
            {value.permissions ?
              <Button
                {...decideTheme(`/${key}`)}
                onClick={() => {navigate(`/${key}`); setShowmenu(false)}}
                children={<>{value.icon}<span>{value.title}</span></>}
                {...value.overthrow}
              >
              </Button> : null}
          </>
        )}
      </Box>
      <Box className='inside-menu' >
        {Object.entries(exit()).map(([key, value]) =>
          <>
            {value.permissions ?
              <Button
                {...decideTheme(`/${key}`)}
                
                onClick={() => navigate(`/${key}`)}
                children={<>{value.icon}<span>{value.title}</span></>}
                {...value.overthrow}
              >
              </Button> : null}
          </>
        )}
      </Box> 
    </Box>
    :
    <Box className='inside-menu'>
      <Button
        sx={{
          color: apptheme.tertiaryColor,
          placeContent: 'flex-start'
        }} 
        onClick={()=>{setShowmenu(true)}}
        children={<MenuIcon />}
        className='main-menu-button hamburguer'
        >
       </Button>
    </Box>
    }
    {showMenu && <Box onClick={()=>setShowmenu(false)} className='backdrop'></Box>} 
    </Box>
  )
};

export default MenuComponent;