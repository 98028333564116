import React, { useEffect, useReducer } from 'react'
import { defaultReducer } from '../Utils/reducers'
import dayjs from 'dayjs'
import DefaultModal from '../Utils/DefaultModal'
import { Box, FormControl, InputLabel, Select, MenuItem, Button, Tooltip } from '@mui/material'
import ColorButton from '../Buttons/ColorButton'
import { weekdayDePara, createTimeArray, createInterval, isTeamDisponible } from './championship-table'


function getUniqueObjectsByProperty(arr, prop) {
   const uniqueMap = new Map();
   const uniqueObjects = [];

   for (const obj of arr) {
      if (!uniqueMap.has(obj[prop])) {
         uniqueMap.set(obj[prop], true);
         uniqueObjects.push(obj);
      }
   }

   return uniqueObjects;
}

const calc_times = (parent, state, generalData) => {
   if (state.teams[0] && state.teams[1]) {
      const actual_date = dayjs(parent.actual_date)
      const weekday_day = weekdayDePara[actual_date.$W]
      const actual_school_disponibility = parent.school[weekday_day]
      const exceptions = generalData.timetable.exceptions
      const exceptionschools = exceptions.filter(
         (each) =>
           dayjs(each.date).format("DDMMYYYY") ===
             dayjs(actual_date).format("DDMMYYYY") &&
           each.type_ === "schools" &&
           each.schoolitems.map((e) => e.id).includes(parent.school.id)
       );
      const time_array = createTimeArray(actual_school_disponibility, createInterval(generalData.cuptable.defaultDuration), exceptionschools)

      Object.entries(time_array).map(([key, value]) => {
         time_array[key] = isTeamDisponible(state.teams[0], key, weekday_day, exceptions, actual_date) && isTeamDisponible(state.teams[1], key, weekday_day, exceptions, actual_date)
         return 0
      })
      return Object.entries(time_array)
   }
   return []
}

const calc_submit = (state, time_arr, teams1_list, teams2_list) => {
   const ret = []

   if (state.category === 0)
      ret.push(<Box>Seleciona uma categoria</Box>)
   if (state.group === '')
      ret.push(<Box>Selecione um grupo</Box>)
   if (!teams1_list.includes(state.teams[0]))
      ret.push(<Box>Selecione o time 1</Box>)
   if (!teams2_list.includes(state.teams[1]))
      ret.push(<Box>Selecione o time 2</Box>)
   if (!Object.fromEntries(time_arr)[state.time]) {
      ret.push(<Box>Selecione um horário válido</Box>)
   }

   if (ret.length === 0)
      return ''
   return ret
}

let category_list = []
let group_list = []
let teams_list = []
// let time_array = []
// let cansubmit = []

const AddMatch = ({ generalData, data, parent, setParent, setShowModal, change }) => {

   const [state, setState] = useReducer(defaultReducer, {
      id: 0,
      category: 0,
      group: '',
      teams: [0, 0],
      time: ''
   })

   const time_array = calc_times(parent, state, generalData)
   const teams1_list = teams_list.filter(each => state.category?.id === each.categories[0]?.id && state.group === each.group && each !== state.teams[1])
   const teams2_list = teams_list.filter(each => state.category?.id === each.categories[0]?.id && state.group === each.group && each !== state.teams[0])
   const cansubmit = calc_submit(state, time_array, teams1_list, teams2_list)

   useEffect(() => {

      teams_list = generalData.timetable.teams
      category_list = getUniqueObjectsByProperty(teams_list.map(each => each.categories[0]), 'id')
      group_list = Array.from(new Set(teams_list.map(each => each.group)))

      if (data?.id)
         setState({
            category: category_list.find(each => each.id === data.category.id),
            time: data.time,
            id: data.id,
            group: data.group,
            teams: [teams_list.find(each => each.id === data.teams[0].id),
            teams_list.find(each => each.id === data.teams[1].id)]
         })
      else
         setState({})
   }, [data, generalData])

   return (
      <>
         <DefaultModal
            handleClose={() => setShowModal(null)}
            title={<Box>{state.id ? 'Editar' : 'Adicionar'} partida - {parent.school.name} - {dayjs(parent.actual_date).toDate().toLocaleDateString()}</Box>}
            content={
               <Box className='default-column-spacing'>
                  <Box className='default-row-spacebetween'>
                     <FormControl size='small' sx={{ width: '100%' }}>
                        <InputLabel id='Categoria-input-label'>Categoria</InputLabel>
                        <Select
                           sx={{ paddingLeft: '0.1rem' }}
                           value={state.category}
                           label='Categoria'
                           labelId='Categoria-input-label'
                           placeholder='Categoria'
                           onChange={(e) => setState({ category: e.target.value })}>
                           <MenuItem value={0}>Selecione *</MenuItem>
                           {category_list.map((each) => (<MenuItem value={each}>{each.name}</MenuItem>))}
                        </Select>
                     </FormControl>
                     <FormControl size='small' sx={{ width: '100%' }}>
                        <InputLabel id='Grupo-input-label'>Grupo</InputLabel>
                        <Select
                           sx={{ paddingLeft: '0.1rem' }}
                           value={state.group}
                           label='Grupo'
                           labelId='Grupo-input-label'
                           placeholder='Grupo'
                           onChange={(e) => setState({ group: e.target.value })}>
                           <MenuItem value={''}>Selecione *</MenuItem>
                           {group_list.map((each) => (<MenuItem value={each}>{each}</MenuItem>))}
                        </Select>
                     </FormControl>
                  </Box>
                  <Box className='default-row-spacebetween'>
                     <FormControl size='small' sx={{ width: '100%' }}>
                        <InputLabel id='Time 1-input-label'>Time 1</InputLabel>
                        <Select
                           sx={{ paddingLeft: '0.1rem' }}
                           value={state.teams[0]}
                           label='Time 1'
                           labelId='Time 1-input-label'
                           placeholder='Time 1'
                           onChange={(e) => {
                              state.teams = [e.target.value, state.teams[1]]
                              setState({ teams: state.teams })
                           }}>
                           <MenuItem value={0}>Selecione *</MenuItem>
                           {teams1_list.map((each) => (<MenuItem value={each}>{each.name}</MenuItem>))}
                        </Select>
                     </FormControl>
                     <FormControl size='small' sx={{ width: '100%' }}>
                        <InputLabel id='Time 2-input-label'>Time 2</InputLabel>
                        <Select
                           sx={{ paddingLeft: '0.1rem' }}
                           value={state.teams[1]}
                           label='Time 2'
                           labelId='Time 2-input-label'
                           placeholder='Time 2'
                           onChange={(e) => {
                              state.teams = [state.teams[0], e.target.value]
                              setState({ teams: state.teams });
                           }}>
                           <MenuItem value={0}>Selecione *</MenuItem>
                           {teams2_list.map((each) => (<MenuItem value={each}>{each.name}</MenuItem>))}
                        </Select>
                     </FormControl>
                  </Box>
                  {time_array.length > 0 &&
                     <Box className='default-row-spacebetween time-wrap' >
                        {time_array.map(([key, value]) =>
                           <>
                              {key === state.time && value ? <ColorButton >{key}</ColorButton>
                                 :
                                 <Button
                                    onClick={e => {
                                       state.time = key
                                       // calc_submit(state, time_array)
                                       setState({ time: state.time })
                                    }}
                                    disabled={!value}
                                    variant='outlined'
                                    color='inherit'
                                 >
                                    {key}
                                 </Button>
                              }
                           </>
                        )}
                     </Box>}
               </Box>
            }
            action={<>
               <Tooltip title={cansubmit}>
                  <span>
                     <ColorButton
                        disabled={cansubmit}
                        onClick={() => {
                           if (state.id)
                              Object.assign(parent.matches.find(each => String(each.id) === String(state.id)), state)
                           else{
                              state.id = Math.random()
                              parent.matches.push(state)
                           }
                           parent.matches = parent.matches.sort((a,b) => Number(a.time.replace(':', '')) - Number(b.time.replace(':', '')))
                           setParent({ ...parent })
                           change()
                           setShowModal()
                        }}>{state.id ? 'Editar' : 'Adicionar'}</ColorButton>
                  </span>
               </Tooltip>
            </>}
         />

      </>
   )
}

export default AddMatch