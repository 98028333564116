import React, { useState } from "react";
import { Box, IconButton, Tooltip} from "@mui/material";
import DefaultModal from "../Utils/DefaultModal";
import { DatePicker } from "@mui/x-date-pickers";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import dayjs from "dayjs";
import DeleteIcon from '@mui/icons-material/Delete';

const MultipleDateModal = ({setParent, parent}) => {
   const [dateList, setDateList] = useState(parent.datelist || [{}]) 
    return (
        <>
      
            <DefaultModal
                    handleClose={() => setParent({ multipledate: null, datelist: dateList})}
                    title="Selecione as Datas"
                    dialogProps={{ maxWidth: "xs" }}
                    
                    content={
                       
                        <>
                            <Box sx={{display: "flex", flexDirection: "column", gap: '0.5rem'}}>
                               {dateList.map( (each, index) => 
                                    <Box>
                                        <DatePicker
                                            key={`datepickerkey${index}`}
                                            sx={{ maxWidth: "50%" }}
                                            label={"Data"}
                                            slotProps={{ textField: { size: "small" } }}
                                            format="DD/MM/YYYY"
                                            ampm={false}
                                            value={dayjs(each)}
                                            onChange={(event) => {
                                                dateList[index] = event.format("YYYY-MM-DD")
                                                setDateList([...dateList])
                                            }}
                                        />

                                        <Tooltip title='Remover Data'><IconButton onClick={() => setDateList(dateList.filter(item => item !== each)) }><DeleteIcon/></IconButton></Tooltip>
                                    </Box>
                                )}
                                <Box sx={{marginTop: "10px"}}>
                                    <Tooltip title='Nova Data'><IconButton onClick={() => setDateList([...dateList, {}]) }><AddCircleOutlineIcon /></IconButton></Tooltip>
                                </Box>
                            </Box>
                            
                        </>
                    }
            />
        
        </>
    )
}


export default MultipleDateModal;