import axios from "axios";
import toast from 'react-hot-toast'
import translatedMessages, {defaultMessages} from "../components/Utils/translatedMessages";

export const defaultBackend = process.env.REACT_APP_URL_PLATESYS

export const api = axios.create({
    baseURL: defaultBackend,
})

api.interceptors.request.use(async (config) => {
    const token = sessionStorage.getItem('token')
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
});

api.interceptors.response.use((response) => { return response }, 
    async function (err) {
        if (! err.response)
            return err;
        const response = err.response

        let responseMessage = defaultMessages['pt-BR']
     
        const errorMessage = response?.data?.detail
    
        if (errorMessage){
            const transMessage = translatedMessages['pt-BR'][errorMessage]
            if (transMessage)
                responseMessage = transMessage
        }

        if(response.status === 500){
            toast.error(responseMessage)
        }

        if (response.status === 400){
            toast.error(JSON.stringify(response.data))
        }

            return response;
    })

export const mediaURL = defaultBackend + '/media/' 

export const createSession = async (email,password) => {

    return api.post('/token/',{email,password})
}

export const getCredentials = async () => {
    return api.get('/integrations/')
}

