import React, { useEffect, useState } from 'react'
import DefaultModal from '../Utils/DefaultModal'
import { api } from '../../services/api'
import ColorButton from '../Buttons/ColorButton'
import { MenuItem, Select } from '@mui/material'

const Locals = ({ parent, handleClose, submit }) => {

    const [schools, setSchools] = useState([])
    const [school, setSchool] = useState(0)

    useEffect(()=>{
        const loadLocals = async () => {
            const res = await api.get('/school/noteams/')
            if (res.status === 200){
                setSchools(res.data)
            }
        }
        loadLocals()
    }, [])

    return (
        <>
            <DefaultModal
                handleClose={handleClose}
                title='Adicionar localidade sem times'
                content={
                    <>
                    <Select
                    sx={{width:'100%'}}
                    value={school}
                    size='small'
                    onChange={(e) => setSchool(e.target.value)}
                    >
                        <MenuItem key='select-0-locals' value='0'>Selecione*</MenuItem>
                        {schools.filter(each => !parent.schools.map(s=> s.id).includes(each.id)).map((each, index) => (
                            <MenuItem key={`select-${index}-locals`} value={each}>{each.name}</MenuItem>
                        ))}
                    </Select>
                    </>
                }
                action={
                    <>
                    <ColorButton 
                    disabled={school === 0}
                    onClick={() =>submit(school)}>Adicionar</ColorButton>
                    </>
                }
            />
        </>
    )
}

export default Locals