import React, { useReducer, useState, useEffect } from 'react'
import ColorButton from '../Buttons/ColorButton'
import { Box, TextField, Skeleton, Tooltip } from '@mui/material'
import { defaultReducer } from '../Utils/reducers'
import { calc_time } from '../School/disponibility-dialog'
import { TimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ptBR from 'dayjs/locale/pt-br';
import { ptBR as localePTBR } from '@mui/x-date-pickers/locales';
import dayjs from 'dayjs'
import ConfirmationDialog from '../Utils/ConfirmationDialog'
import Round from './round'
import AddRound from './addround'
import useVisibility from '../Utils/isVisible'
import DefaultModal from '../Utils/DefaultModal'
import DisplayMatches from './display-matches'
import { downloadExcel } from "react-export-table-to-excel";
import IntegerOnly from '../Utils/IntegerOnly'

export const weekdayDePara = {
    0: 'dom',
    1: 'seg',
    2: 'ter',
    3: 'qua',
    4: 'qui',
    5: 'sex',
    6: 'sab'
}

const generateTableXLSX = (table) => {
    const header = ['Rodada', 'Categoria', 'Grupo', 'Time 1', 'Time 2', 'Local', 'Data', 'Horário']
    const body = []
    table.map((round, index) => {
        round.matches.map(match => {
            body.push(
                [index + 1, match.teams[0].categories[0].name, match.teams[0].group, match.teams[0].name,
                match.teams[1].name, round.school.name, dayjs(round.actual_date).toDate().toLocaleDateString(),
                match.time]
            )
        })
    })
    const thisDate = dayjs().toDate().toLocaleDateString()
    downloadExcel({
        fileName: `tabela-da-copa-${thisDate}`,
        sheet: 'tabela',
        tablePayload: {
            header: header,
            body: body
        }
    })
}

const calcCategories = (teams) => {
    const result = {}
    teams.map(each => each.categories.map(e => {
        result[e.id] = e.name
        return 0
    })
    )
    return result
}

const calcChaves = (teams) => {
    const result = {}
    teams?.map(each => {
        result[each.group] = 1
        return 0
    })

    return Object.keys(result).length
}

function zfill(number, length) {
    let result = number.toString();
    let pad = length - result.length;
    while (pad > 0) {
        result = '0' + result;
        pad--;
    }
    return result;
}

// create an array with gameslots
//[0800 - 0840]
export const createTimeArray = (arr, interval, exceptionsschools) => {
    const timearr = {}
    arr.map(each => {
        const [hourini, timeini] = each.timeini.split(':')
        const [hourend, timeend] = each.timeend.split(':')
        const ini = dayjs().set('hour', hourini).set('minutes', timeini).set('second', 1)
        const end = dayjs().set('hour', hourend).set('minutes', timeend).set('second', 0)
        let currtime = ini
        while (currtime < end) {
            const hour = zfill(currtime.hour(), 2)
            const minutes = zfill(currtime.minute(), 2)
            timearr[`${hour}:${minutes}`] = null
            currtime = currtime.add(interval, 'minutes')
        }
        return 0
    })

    exceptionsschools.map((each) => {
        if (each.permission) {
          const hourini = dayjs(each.timeini)
          const hourend = dayjs(each.timeend)
    
          let currtime = hourini
          while (currtime < hourend) {
            const hour = zfill(currtime.hour(), 2);
            const minutes = zfill(currtime.minute(), 2);
            timearr[`${hour}:${minutes}`] = null;
            currtime = currtime.add(interval, "minutes");
          }
        } else {
    
          const hourini = dayjs(each.timeini);
          const hourend = dayjs(each.timeend);
    
          Object.keys(timearr).map((time) => {
            const [hourTime, minuteTime] = time.split(":");
            const timeInt = dayjs(each.timeini)
              .set("hour", hourTime)
              .set("minute", minuteTime)
              .set("second", 30);
    
            if (timeInt >= hourini && timeInt <= hourend)  
              delete timearr[time];
          });
        }
      });

    return timearr
}

const getRandomFromArray = (arr) => {
    return arr[Math.floor(Math.random() * arr.length)]
}

const verifyteams = (teamsexceptions, applied_teams) => {
    return teamsexceptions.find((each) => each.id === applied_teams.id);
  };

export const isTeamDisponible = (applied_teams, key, dayweek, exceptions, actual_date) => {
    const disponibilities = applied_teams[dayweek]

    const exceptionsteams = exceptions.filter(
        (each) =>
          each.type_ === "teams" &&
          isActualDate(each.date, actual_date) &&
          verifyteams(each.teamsitems, applied_teams)
      );

    const [hourset, timeset] = key.split(':')
    const selectedTime = dayjs().set('hour', hourset).set('minutes', timeset).set('second', 30)

    if (exceptionsteams.length > 0) {
        for (let index = 0; index < exceptionsteams.length; index++) {
          const element = exceptionsteams[index];
    
          const [hourini, timeini] = dayjs(element.timeini)
            .format("HH:mm")
            .split(":");
          const [hourend, timeend] = dayjs(element.timeend)
            .format("HH:mm")
            .split(":");
    
          const intervalIni = dayjs()
            .set("hour", hourini)
            .set("minutes", timeini)
            .set("second", 0);
    
          const intervalEnd = dayjs()
            .set("hour", hourend)
            .set("minutes", timeend)
            .set("second", 59);
    
          if (selectedTime <= intervalEnd && selectedTime >= intervalIni) {
            return element.permission;
          }
        }
      }

    for (let index = 0; index < disponibilities.length; index++) {
        const element = disponibilities[index];

        const [hourini, timeini] = element.timeini.split(':')
        const [hourend, timeend] = element.timeend.split(':')
        const intervalIni = dayjs().set('hour', hourini).set('minutes', timeini).set('second', 0)
        const intervalEnd = dayjs().set('hour', hourend).set('minutes', timeend).set('second', 59)

        if (selectedTime <= intervalEnd && selectedTime >= intervalIni) {
            return true
        }

    }
    return false

}


//Create an entire round and add + 1 to this school on usedChampionship and on matches
const createRound = (teams, actual_date, school, usedChampionship, interval, roundLimit, limitConfronts, exceptions) => {
    const round = { school: school, actual_date: actual_date, matches: [] }
    const dayweek = weekdayDePara[actual_date.$W]
    const disponibility = school[dayweek]

    const exceptionsschools = exceptions.filter(
        (each) =>
          dayjs(each.date).format("DDMMYYYY") ===
            dayjs(actual_date).format("DDMMYYYY") &&
          each.type_ === "schools" &&
          each.schoolitems.map((e) => e.id).includes(school.id)
      );

      const timearr = createTimeArray(disponibility, interval, exceptionsschools)
    // 8# Cant allow a team to play again agains the same team

    Object.keys(timearr).map((key) => {

        let playable_teams = teams.filter(each => isTeamDisponible(each, key, dayweek, exceptions, actual_date))
        playable_teams = playable_teams.filter(team => !team.played_days.includes(actual_date)) 

        if (playable_teams.length > 1 && round.matches.length < roundLimit){
            let possible_matches = playable_teams
            .filter(team1 => playable_teams.filter(e => e !== team1).find(team2 => team1.group === team2.group && team1.categories[0].id === team2.categories[0].id))
            .filter(team => team.games < limitConfronts)
            if (possible_matches.length > 1){
                // priority to teams from school
                let possible_first_teams = possible_matches.filter(each => school.teams?.map(e => e.id).includes(each.id)).filter(team => team.games < limitConfronts)
                if (possible_first_teams.length === 0){
                    possible_first_teams = possible_matches
                }
                let first_team = getRandomFromArray(getTeamWithLessGames(possible_first_teams))
                let second_team = possible_matches
                .filter(e => e !== first_team)
                .find(team => (first_team.categories[0].id === team.categories[0].id && first_team.group === team.group && !first_team.played_against[team.id]))
                
                while (!second_team && possible_matches.length > 0){
                    possible_matches = possible_matches.filter(each => each !== first_team)
                    first_team = getRandomFromArray(getTeamWithLessGames(possible_matches))
                    second_team = possible_matches
                    .filter(e => e !== first_team)
                    .find(team => (first_team.categories[0].id === team.categories[0].id && first_team.group === team.group && !first_team.played_against[team.id]))
                }

                if (first_team && second_team){
                    //metadata
                    first_team.games +=1
                    second_team.games +=1
                    first_team.played_days.push(actual_date)
                    second_team.played_days.push(actual_date)
                    first_team.played_against[second_team.id] = {name: second_team.name, date: actual_date, category: second_team.categories[0].name, group: second_team.group}
                    second_team.played_against[first_team.id] = {name: first_team.name, date: actual_date, category: first_team.categories[0].name, group: first_team.group}
                    //add
                    round.matches.push({
                        category: first_team.categories[0],
                        group: first_team.group,
                        time: key,
                        teams: [first_team, second_team],
                        id: Math.random()
                    })
                } 
                // else{
                //     console.log(first_team, second_team)
                //     round.matches.push({
                //         category: {},
                //         group: "",
                //         time: key,
                //         teams: [{}, {}],
                //         id: Math.random()
                //     })
                // }
            }
        }
    })

    usedChampionship[String(school.id)] += 1
    return round
}

const getTeamWithLessGames = (teams) => {
    const lowestNumber = Math.min(...teams.map(each => each.games))
    const possibleMatches = teams.filter(each => each.games === lowestNumber)
    return possibleMatches
}


//Select the school with less games
const selectRandomSchool = (usedChampionship, selected_schools) => {
    const schoolList = Object.entries(usedChampionship).filter(([key, value]) => selected_schools.includes(Number(key)))
    const lowestNumber = Math.min(...schoolList.map(([key, value]) => value))
    const searchableSchools = schoolList.filter(([key, value]) => value === lowestNumber)
    return getRandomFromArray(searchableSchools.map(([key, value]) => key)) //[Math.floor(Math.random()* searchableSchools.length)]
}

export const createInterval = (interval) => {
    const [hour, minutes] = interval.split(':')
    const iniHour = Number(hour) * 60
    const iniMinutes = Number(minutes)
    return iniHour + iniMinutes
}

const isActualDate = (dataexception, actual_date) => {
    const actualdate = dayjs(actual_date).format("YYYY-MM-DD");
    if (dataexception === actualdate) {
      return true;
    }
  };

  const verifyschools = (schoolitems, schools) => {
    return schools.map((schoolsid) =>
      schoolitems.find((each) => each.id === schoolsid)
    );
  };

const selectDisponibileSchool = (actual_date, schools, exceptions) => {
    const dayweek = weekdayDePara[actual_date.$W]
    const disponible_schools = schools.filter(school => school[dayweek].length > 0)
    .filter(school => !(day_school[actual_date]?.length > 0 && day_school[actual_date]?.includes(school?.id))) // limit school of playing again on the same round
    .map(each => each.id)

    const exceptionsschools = exceptions.filter(
        (each) =>
          each.type_ === "schools" &&
          isActualDate(each.date, actual_date) &&
          verifyschools(each.schoolitems, schools)
      );

      const permssionexception = exceptionsschools.filter(
        (each) => each.permission
      );
      const listschoolsid = permssionexception
        .map((each) => each.schoolitems.map((e) => e.id))
        .flat();
    
      const listdisponibleschools = [
        ...new Set([...listschoolsid, ...disponible_schools]),
      ];
        
      return listdisponibleschools;
}

// 08/04/2024 - A mesma escola não pode sediar 2 rodadas no mesmo dia
// O mesmo time não pode jogar 2 partidas no mesmo dia
let day_school = {}
const limit_agains_same_team = 1

const createTable = (state, parent, setState) => {
    const exceptions = parent.timetable.exceptions ? parent.timetable.exceptions : [];
    const table = []
    const schools = parent.timetable.schools
    const teams = parent.timetable.teams
    const usedChampionship = Object.fromEntries(schools.map(each => [each.id, 0]))
    const first_date = dayjs(parent.init_date)
    const last_date = dayjs(parent.end_date)
    const interval = createInterval(state.defaultDuration)
    let actual_date = first_date
    let internal_round = 0
    const roundsDay = state.roundsDay
    //const matches = createMatches(teams)
    const roundLimit = state.matchDay
    const limitConfronts = state.confronts
    day_school = {}

    teams.map(team => { // Init team games 
        team.games = 0 // amount of matches
        team.played_days = [] // days which the team played (cant play again on the same day)
        team.played_against = {} // played agains the same team
    })

    const firstSchool = parent.timetable.schools.find(each => each.priority)

    if (firstSchool)
        while (actual_date < last_date) {
            const round = createRound(teams, actual_date, firstSchool, usedChampionship, interval, roundLimit, limitConfronts, exceptions) // try to create round
            if (round) {
                day_school[actual_date] = [firstSchool.id]
                actual_date = actual_date.add(1, 'day') // pass the day ( we will decide later if it should add to internal_round or just pass the day)
                table.push(round) // if round created, push to table
                break // if round created, we will break the loop
            }  // else, will loop again, till get to the final data
            actual_date = actual_date.add(1, 'day')  // pass the day
        }

    while (actual_date <= last_date && table.filter(m=>m.matches.length > 0)?.length < state.roundAmount) { // now, with the first school selected
        const selected_schools = selectDisponibileSchool(actual_date, schools, exceptions) // change to get schools with disponibility
        const selectedSchoolId = selectRandomSchool(usedChampionship, selected_schools) // from disponible schools, get the one with less matches
        const selectedSchool = schools.find(each => Number(each.id) === Number(selectedSchoolId))

        if (selectedSchool){
            const round = createRound(teams, actual_date, selectedSchool, usedChampionship, interval, roundLimit, limitConfronts, exceptions) // try to create the round     
            if (round) {
                day_school[actual_date] = day_school[actual_date]?.length > 0 ? [...day_school[actual_date], selectedSchool.id] : [selectedSchool.id]
                table.push(round) // if round created, push to table
                internal_round += 1 // if round is created, we will add +1 to internal round
            }
            if (internal_round >= Number(roundsDay)) {
                actual_date = actual_date.add(1, 'day') // if we have reached the limit of rounds per day, pass to the next day
                internal_round = 0 // zero to inernal round.
            }
        } else{
            actual_date = actual_date.add(1, 'day') // if we have reached the limit of rounds per day, pass to the next day
            internal_round = 0 // zero to inernal round.
        }


    }
    setState({ table: table.filter(each => each.matches.length > 0) })
}

const ChampionshipTable = ({ parent, setParent }) => {

    const [state, setState] = useReducer(defaultReducer, {
        defaultDuration: '00:40',
        roundsDay: 2,
        matchDay: 8,
        roundAmount: 10,
        confronts: 5,
        table: null,
        ...parent.cuptable
    })
    const [confirmRecreate, setConfirmRecreate] = useState(false)
    const [confirmInitiate, setConfirmInitiate] = useState(false)
    const [showMatches, setShowMatches] = useState(false)

    useEffect(() => {
        setState({ ...parent.cuptable })
    }, [parent, setState])

    useEffect(() => {
        parent.cuptable = state
    }, [state])

    return (
        <Box className='teams-container'>
            {showMatches && <DefaultModal
                title='Verificar jogos'
                dialogProps={{ maxWidth: 'md' }}
                handleClose={() => setShowMatches(false)}
                content={<DisplayMatches data={state.table} teams={parent.timetable.teams}/>}
            />}
            {confirmInitiate && <ConfirmationDialog
                content={<Box sx={{ whiteSpace: 'normal' }}>Deseja iniciar o campeonato?
                    Após iniciar, não será possível mais editar os times e a tabela de horas.
                    Será possível editar a tabela do campeonato livremente.</Box>}
                handleClose={() => { setConfirmInitiate(false) }}
                onConfirm={() => { setParent({ ...parent, initiated: true }); setConfirmInitiate(false) }}
            />}
            {confirmRecreate && <ConfirmationDialog
                content={<Box sx={{ whiteSpace: 'normal' }}>Deseja gerar a tabela do campeonato novamente? Todas as suas modificações serão perdidas.</Box>}
                handleClose={() => { setConfirmRecreate(false) }}
                onConfirm={() => { createTable(state, parent, setState); setConfirmRecreate(false) }}
            />}

            <Box className='school-header'>
                {!parent.initiated &&
                    <Tooltip title={!state.table ? 'Adicione alguma rodada' : ''}>
                        <span style={{ width: '100%' }}>
                            <ColorButton
                                disabled={!state.table}
                                sx={{ width: '100%' }}
                                onClick={() => { setConfirmInitiate(true) }}
                            >
                                Iniciar campeonato
                            </ColorButton>
                        </span>
                    </Tooltip>
                }
            </Box>
            <Box className='school-name' sx={{ width: '100%' }}>
                <TextField
                    label='Limite de rodadas'
                    type='number'
                    disabled={parent.initiated}
                    value={state.roundAmount}
                    onChange={(e) => setState({ roundAmount: IntegerOnly(e.target.value, 1, 1000) })}
                    size='small'
                    sx={{ flex: 1 }}
                />
                <TextField
                    label='Limite de confrontos'
                    type='number'
                    disabled={parent.initiated}
                    value={state.confronts}
                    onChange={(e) => setState({ confronts: IntegerOnly(e.target.value, 1, 1000) })}
                    size='small'
                    sx={{ flex: 1 }}
                />
                <TextField
                    label='Limite de partidas/rodada'
                    type='number'
                    disabled={parent.initiated}
                    value={state.matchDay}
                    onChange={(e) => setState({ matchDay: IntegerOnly(e.target.value, 1, 1000) })}
                    size='small'
                    sx={{ flex: 1 }}
                />
                <TextField
                    label='Limite de rodadas por dia'
                    type='number'
                    value={state.roundsDay}
                    onChange={(e) => setState({ roundsDay: IntegerOnly(e.target.value, 1, 1000) })}
                    size='small'
                    disabled={parent.initiated}
                    sx={{ flex: 1 }}
                />
                <LocalizationProvider sx={{ justifyContent: 'space-between', flex: 1 }} dateAdapter={AdapterDayjs} adapterLocale={ptBR}
                    localeText={localePTBR.components.MuiLocalizationProvider.defaultProps.localeText}
                >
                    <TimePicker
                        sx={{ width: '100%', minWidth: '10rem', flex: 1 }}
                        label='Duração média de cada partida'
                        slotProps={{ textField: { size: 'small' } }}
                        value={calc_time(state.defaultDuration)}
                        disabled={parent.initiated}
                        onChange={e => setState({ defaultDuration: e.format('HH:mm') })}
                        ampm={false}
                    />
                </LocalizationProvider>
                <TextField
                    label='Qtd. Times'
                    type='number'
                    defaultValue={parent.teams.length}
                    disabled
                    size='small'
                    sx={{ flex: 1 }}
                />
                <TextField
                    label='Qtd. Localidades'
                    type='number'
                    defaultValue={parent.timetable?.schools?.length}
                    disabled
                    size='small'
                    sx={{ flex: 1 }}
                />
                {/* <TextField
                    label='Qtd. Categorias'
                    type='number'
                    defaultValue={Object.keys(calcCategories(parent.teams))?.length}
                    disabled
                    size='small'
                    sx={{ flex: 1 }}
                />
                <TextField
                    label='Qtd. Chaves'
                    type='number'
                    defaultValue={calcChaves(parent.timetable?.teams)}
                    disabled
                    size='small'
                    sx={{ flex: 1 }}
                /> */}
            </Box>
            <Box className='school-header' sx={{ justifyContent: 'end' }}>
                <ColorButton disabled={!state.table} onClick={() => setShowMatches(true)}>Verificar jogos</ColorButton>
                <ColorButton onClick={() => generateTableXLSX(state.table)}>Exportar xlsx</ColorButton>
            </Box>
            {!parent.initiated &&
                <Box className='school-header'>
                    <Tooltip title={parent.timetable.schools.length === 0 ? 'Adicione uma localidade' : ''}>
                        <span style={{ width: '100%' }}>
                            <ColorButton
                                sx={{ width: '100%' }}
                                disabled={parent.timetable.schools.length === 0}
                                onClick={() => {
                                    !state.table ? createTable(state, parent, setState) :
                                        setConfirmRecreate(true)
                                }}
                            >
                                Criar tabela do campeonato
                            </ColorButton>
                        </span>
                    </Tooltip>
                </Box>}
            <Box className='table-divider'>
                <AddRound generalData={parent} parent={state} setParent={setState} index={0} />
                {
                    state.table?.map((round, index) => (
                        <ShowComponent round={round} index={index}>
                            <Round key={`${index}-item-table`} generalData={parent} index={index} data={round} parent={state} setParent={setState} />
                            <AddRound key={`${index}-divider-table`} generalData={parent} index={index + 1} parent={state} setParent={setState} />
                        </ShowComponent>
                    ))
                }
            </Box>
        </Box>
    )
}

const ShowComponent = ({ round, index, children }) => {
    const matches = (round.matches.length)
    const [isVisible, currentElement] = useVisibility(matches*40)
    
    const height = (matches) * 40 + (matches - 1) * 8 + 160.8

    return (<Box ref={currentElement}>
        {isVisible || index < 3 ?
            children
            :
            <Skeleton variant="rectangular" width={'100%'} height={height} />
        }
    </ Box>
    )

}


export default ChampionshipTable