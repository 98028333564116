import React, { useEffect, useState } from 'react'

import ColorButton from '../Buttons/ColorButton'
import { DataGrid } from '@mui/x-data-grid'
import { Box, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmationDialog from '../Utils/ConfirmationDialog';
import TimeTableDisponibilityModal from './timetable-disponibility-dialog'

const columns = (setShowModal, setDeleteModal, disabled) => {
    const data = [
        {
            field: 'name',
            headerName: 'Nome',
            flex: 1,
        },
        {
            field: 'timeini',
            headerName: 'Início',
            flex: 1,
            valueGetter: (params) => {
                return params.row.timeini
            }
        },
        {
            field: 'timeend',
            headerName: 'Fim',
            flex: 1,
            valueGetter: (params) => {
                return params.row.timeend
            }
        },
        {
            field: 'actions',
            headerName: 'Ações',
            renderCell: (props) => {
                return (
                    <Box >
                        <IconButton onClick={() => setShowModal(props.row)}><EditIcon /></IconButton>
                        <IconButton onClick={() => setDeleteModal(props.row)}><DeleteIcon /></IconButton>
                    </Box>
                )
            }
        }
    ]
    return disabled ? data.filter(each => each.field !== 'actions') : data
}

const TimeTableDisp = ({ data, submit, disabled }) => {

    const [tableData, setTableData] = useState(data)

    const [showModal, setShowModal] = useState()
    const [deleteModal, setDeleteModal] = useState()

    useEffect(()=>{
        submit(tableData)
    }, [tableData, submit])

    return (
        <Box className='teams-container'>
            {showModal && <TimeTableDisponibilityModal data={showModal} handleClose={() => setShowModal(false)} parent={tableData} setParent={setTableData} />}
            {!disabled && <ColorButton sx={{width:'100%'}} className='buttonstyle' onClick={() => setShowModal({})}>Nova Disponibilidade</ColorButton>}
            {deleteModal && <ConfirmationDialog
                content={`Deseja deletar ${deleteModal.name}?`}
                handleClose={() => setDeleteModal()}
                onConfirm={() => {
                    setTableData(tableData.filter(each => each !== deleteModal))
                    setDeleteModal()
                }} />}
            <DataGrid
                rows={tableData}
                columns={columns(setShowModal, setDeleteModal, disabled)}
                pagination
                getRowId={(each) => each.idx}
                density='compact'
                hideFooter={true}
            />
        </Box>
    )
}

export default TimeTableDisp