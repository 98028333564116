
import React, { useReducer, useState } from 'react'
import DefaultModal from '../Utils/DefaultModal'
import ColorButton from '../Buttons/ColorButton'
import { defaultReducer } from '../Utils/reducers'
import { TextField, Tooltip } from '@mui/material'
import { Box, InputLabel, FormControl, Select, MenuItem } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ptBR from 'dayjs/locale/pt-br';
import { ptBR as localePTBR } from '@mui/x-date-pickers/locales';
import { TimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'

export const weekdays = {
    0: 'Domingo',
    1: 'Segunda',
    2: 'Terça',
    3: 'Quarta',
    4: 'Quinta',
    5: 'Sexta',
    6: 'Sábado',
}

export const calc_time = (value) => {
    if (!value) return ''
    const [hour, minutes] = value.split(':')
    return dayjs().hour(hour).minute(minutes)
}

const submit = (state, handleClose, parent, setParent, multiple) => {

    if (state.multiple){
        const states = []
        multiple.map(each => {
            const {multiple, ...data} = state
            states.push({
                ...data,
                weekdayini: String(each),
                weekdayend: String(each),
                idx: Math.random()
            })
        })
        console.log(states)
        setParent({ disponibilities: [...parent.disponibilities, ...states] })
        handleClose()
        return
    }

    if (state.idx) {
        const old_state = parent.disponibilities.find(each => each.idx === state.idx)
        Object.assign(old_state, state)
    }
    else
        setParent({ disponibilities: [...parent.disponibilities, { ...state, idx: Math.random() }] })
    handleClose()

}

const validateDisponibility = (state) => {
    const answer = []

    if (state.weekdayini === '' && !state.multiple)
        answer.push(<Box>Adicione um dia de início.</Box>)
    if (state.weekdayend === '' && !state.multiple)
        answer.push(<Box>Adicione um dia de fim.</Box>)
    if (state.timeini === '')
        answer.push(<Box>Adicione uma hora de início.</Box>)
    if (state.timeend === '')
        answer.push(<Box>Adicione uma hora de fim.</Box>)

    if (answer.length === 0)
        return ''

    return answer
}

const DisponibilityModal = ({ data, handleClose, parent, setParent }) => {

    const [state, setState] = useReducer(defaultReducer, {
        name: '',
        weekdayini: '',
        weekdayend: '',
        timeini: '',
        timeend: '',
        ...data
    })

    const [multipleList, setMultipleList] = useState([])

    const validate_disponibility = validateDisponibility(state)

    return (
        <>
            <DefaultModal
                title={`${data.id ? 'Editar' : 'Nova'}${state.multiple ? 's': ''} ${state.multiple ? 'multiplas': ''} disponibilidade${state.multiple ? 's': ''}`}
                content={<Box className='default-column-spacing'>
                    <TextField
                        label='Descrição'
                        size='small'
                        fullWidth
                        value={state.name}
                        inputProps={{
                            maxLength: 255
                        }}
                        onChange={(e) => setState({ name: e.target.value })}
                    />
                    <Box className='default-row-spacebetween'>
                        {data.multiple ? 
                        <Box sx={{display:'flex', gap:'1rem', justifyContent: 'space-between', flexWrap:'wrap', width:'100%'}} >
                            {Object.entries(weekdays).map(([key, value]) => (
                                <ColorButton onClick={()=>{
                                    let newlist = [...multipleList]
                                    if (newlist.includes(key)){
                                        newlist = newlist.filter(each => each !== key)
                                    } else {
                                        newlist.push(key)
                                    }
                                    setMultipleList(newlist)                                        
                                }} reverse={!multipleList.includes(key)} >{value.substring(0, 3)}</ColorButton>
                            ))}
                        </Box> 
                        : <>
                            <FormControl size='small' sx={{ width: '100%' }}>
                                <InputLabel id='dayini-input-label'>Dia de início</InputLabel>
                                <Select
                                    sx={{ paddingLeft: '0.1rem' }}
                                    value={state.weekdayini}
                                    label='Dia de início'
                                    labelId='dayini-input-label'
                                    placeholder='Dia de início'
                                    onChange={(e) => setState({ weekdayini: e.target.value })}>
                                    {Object.entries(weekdays).map(([key, value]) => (<MenuItem value={key}>{value}</MenuItem>))}
                                </Select>
                            </FormControl>
                            <FormControl size='small' sx={{ width: '100%' }}>
                                <InputLabel id='dayend-input-label'>Dia de Fim</InputLabel>
                                <Select
                                    sx={{ paddingLeft: '0.1rem' }}
                                    value={state.weekdayend}
                                    label='Dia de Fim'
                                    labelId='dayend-input-label'
                                    placeholder='Dia de Fim'
                                    onChange={(e) => setState({ weekdayend: e.target.value })}>
                                    {Object.entries(weekdays).map(([key, value]) => (<MenuItem value={key}>{value}</MenuItem>))}
                                </Select>
                            </FormControl>
                        </>}
                    </Box>
                    <Box className='default-row-spacebetween'>
                        <LocalizationProvider sx={{ justifyContent: 'space-between' }} dateAdapter={AdapterDayjs} adapterLocale={ptBR}
                            localeText={localePTBR.components.MuiLocalizationProvider.defaultProps.localeText}
                        >
                            <>
                                <TimePicker
                                    sx={{ width: '100%' }}
                                    label={'Hora de início'}
                                    slotProps={{ textField: { size: 'small' } }}
                                    value={calc_time(state.timeini)}
                                    onChange={e => setState({ timeini: e.format('HH:mm') })}
                                    ampm={false}
                                />
                                <TimePicker
                                    sx={{ width: '100%' }}
                                    label={'Hora de fim'}
                                    slotProps={{ textField: { size: 'small' } }}
                                    value={calc_time(state.timeend)}
                                    onChange={e => setState({ timeend: e.format('HH:mm') })}
                                    ampm={false}
                                />
                            </>
                        </LocalizationProvider>
                    </Box>
                </Box>}
                action={<>
                    <Tooltip title={validate_disponibility}>
                        <span>
                            <ColorButton
                                disabled={validate_disponibility}
                                onClick={() => submit(state, handleClose, parent, setParent, multipleList)}>
                                Confirmar
                            </ColorButton>
                        </span>
                    </Tooltip>
                </>}
                handleClose={handleClose}
            />
        </>
    )
}

export default DisponibilityModal