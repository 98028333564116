import React, { useReducer } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ptBR from "dayjs/locale/pt-br";
import { ptBR as localePTBR } from "@mui/x-date-pickers/locales";
import { TimePicker } from "@mui/x-date-pickers";
import ColorButton from "../Buttons/ColorButton";
import { Box, InputLabel, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { defaultReducer } from "../Utils/reducers";
import DefaultModal from "../Utils/DefaultModal";
import MultipleDateModal from "./multipledatemodal";
import dayjs from "dayjs";

const addException = (parent, setParent, state, data) => {
  if (data && data.id) {
    const updatedExceptions = parent.exceptions.map((exception) =>
      exception.id === data.id ? { ...exception, ...state } : exception
    );

    setParent({ exceptions: updatedExceptions });
    setParent({ exceptionModal: null });
  } else {

    const newList = []
    if (!state.datelist) {
      newList.push(state.date);
    } else {
      newList.push(...state.datelist);
    }

    const newExceptions = newList.map((d) => {
      return {
        id: Math.random(),
        descricao: state.descricao,
        type_: state.type_,
        schoolitems: state.schoolitems,
        teamsitems: state.teamsitems,
        date: d,
        timeini: state.timeini,
        timeend: state.timeend,
        permission: state.permission,
      };
    });
  
    setParent({ exceptions: [...parent.exceptions, ...newExceptions], exceptionModal: null });
  }
};

export const ExceptionModal = ({ data, parent, setParent, timetable, exceptionType }) => {
  const [state, setState] = useReducer(defaultReducer, {
    descricao: data.descricao ? data.descricao : "",
    type_: data.type_ ? data.type_ : "teams",
    schoolitems: data.schoolitems ? timetable.schools.filter(each => data.schoolitems.map(e => e.id).includes(each.id)) : [],
    teamsitems: data.teamsitems ? timetable.teams.filter(each => data.teamsitems.map(e => e.id).includes(each.id) ) : [],
    date: data.date ? data.date : "",
    datelist: null,
    timeini: data.timeini ? data.timeini : "",
    timeend: data.timeend ? data.timeend : "",
    permission: "permission" in data ? data.permission : true,
    multipledate: null
  });

  return (
    <DefaultModal
      handleClose={() => setParent({ exceptionModal: null})}
      dialogProps={{ maxWidth: "sm" }}
      title={data && data.id ? "Editar Exceção" : exceptionType === 'multiple' ? "Adicionar Multiplas Exceções" : "Adicionar Exceções"}
      action={
        <Box>
          <ColorButton sx={{ width: "100%" }} onClick={() => addException(parent, setParent, state, data)}>
            {data && data.id ? "Editar Exceção" : "Adicionar Exceção"}
          </ColorButton>
        </Box>
      }
      content={
        <>
          <Box sx={{ display: "flex", marginBottom: "10px" }}>
            <ColorButton
              sx={{ flex: 1 }}
              onClick={() => setState({ type_: "teams" })}
              reverse={state.type_ !== "teams"}
            >
              Times
            </ColorButton>
            <ColorButton
              sx={{ flex: 1 }}
              onClick={() => setState({ type_: "schools" })}
              reverse={state.type_ !== "schools"}
            >
              Localidades
            </ColorButton>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <TextField
              label="Nome"
              value={state.descricao}
              onChange={(e) => setState({ descricao: e.target.value })}
              size="small"
              placeholder="Nome da Exceção"
              sx={{ width: "100%", minWidth: "20rem", marginBottom: "5px" }}
            />
            <FormControl size="small" sx={{ width: "100%" }}>
              <InputLabel id="Time-input-label">
                {state.type_ === "teams" ? "Time" : "Localidades"}
              </InputLabel>
              <Select
                sx={{ marginBottom: "5px" }}
                label={state.type_ === "teams" ? "Time" : "Localidades"}
                labelId="Time-input-label"
                placeholder={state.type_ === "teams" ? "Time" : "Localidades"}
                multiple
                value={
                  state.type_ === "teams" ? state.teamsitems : state.schoolitems
                }
                onChange={
                  state.type_ === "teams"
                    ? (e) => setState({ teamsitems: e.target.value })
                    : (e) => setState({ schoolitems: e.target.value })
                }
              >
                <MenuItem value={0}>Selecione *</MenuItem>
                {state.type_ === "teams"
                  ? timetable.teams.map((each) => (
                      <MenuItem value={each}>{each.name} - {each.categories[0].name} - {each.group}</MenuItem>
                    ))
                  : timetable.schools.map((each) => (
                      <MenuItem value={each}>{each.name}</MenuItem>
                    ))}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ display: "flex" }}>
            <LocalizationProvider
              sx={{ justifyContent: "space-between" }}
              dateAdapter={AdapterDayjs}
              adapterLocale={ptBR}
              localeText={
                localePTBR.components.MuiLocalizationProvider.defaultProps
                  .localeText
              }
            >
              { exceptionType === 'single' && (
                <DatePicker
                  sx={{
                    marginRight: ".5rem",
                    width: "100%",
                    minWidth: "10rem",
                    flex: '1'
                  }}
                  label={"Data"}
                  slotProps={{ textField: { size: "small" } }}
                  format="DD/MM/YYYY"
                  value={dayjs(state.date)}
                  onChange={(e) => setState({ date: e.format("YYYY-MM-DD") })}
                  ampm={false}
                />
              )}  

              { exceptionType === 'multiple' && (
                <>
                  <Box>
                    {state.multipledate && (
                      <MultipleDateModal
                        parent={state}
                        setParent={setState}
                      />
                    )}

                    <ColorButton
                      sx={{ flex: 1, marginRight: "10px" }}
                      onClick={() => setState({multipledate : {} })}
                    >
                      Selecione as Datas
                    </ColorButton>
                  </Box>
                </>

              )}  
              <TimePicker
                sx={{ width: "100%", marginRight: ".5rem" , flex: 1}}
                label={"Hora de início"}
                slotProps={{ textField: { size: "small" } }}
                value={dayjs(state.timeini)}
                onChange={(e) => setState({ timeini: e })}
                ampm={false}
              />
              <TimePicker
                sx={{ width: "100%", flex: 1 }}
                label={"Hora de Fim"}
                slotProps={{ textField: { size: "small" } }}
                value={dayjs(state.timeend)}
                onChange={(e) => setState({ timeend: e })}
                ampm={false}
              />
            </LocalizationProvider>
          </Box>

          <Box sx={{ display: "flex", marginTop: "10px" }}>
            <ColorButton
              sx={{ flex: 1 }}
              onClick={() => setState({ permission: true })}
              reverse={state.permission !== true}
            >
              Permitir
            </ColorButton>
            <ColorButton
              sx={{ flex: 1 }}
              onClick={() => setState({ permission: false })}
              reverse={state.permission !== false}
            >
              Não permitir
            </ColorButton>
          </Box>
        </>
      }
    />
  );
};

export default ExceptionModal;
