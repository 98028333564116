import React from 'react'
import {  GridToolbarContainer
    ,GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid'


const CustomToolbar = (extraComponent) => {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarExport
                slotProps={{
                    tooltip: { title: 'Export data' },
                    button: { variant: 'outlined' },
                }}
            />
            {extraComponent}
        </GridToolbarContainer>
    );
}

export default CustomToolbar