import { Box, IconButton, ListItem, List } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import React, { useState, useEffect } from 'react'
import VisibilityIcon from '@mui/icons-material/Visibility';
import DefaultModal from '../Utils/DefaultModal';
import dayjs from 'dayjs';
import { weekdayDePara } from './championship-table';

const columns = (setShowDetails) => {
    return [
        {
            field: 'category',
            headerName: 'Categoria',
            flex: 1
        },
        {
            field: 'group',
            headerName: 'Grupo',
            flex: 1,
        },
        {
            field: 'team',
            headerName: 'Equipe',
            flex: 1
        },
        {
            field: 'amount',
            headerName: 'Jogos',
            flex: 1
        },
        {
            field: 'matches',
            headerName: 'Partidas',
            flex: 1,
            renderCell: ({ row }) => <IconButton onClick={() => { setShowDetails(row) }}><VisibilityIcon /></IconButton>

        }
    ]
}

const DisplayMatches = ({ data, teams }) => {
    const [table, setTable] = useState(null)
    const [showDetails, setShowDetails] = useState(null)
    useEffect(() => {
        const table = {}

        teams.map(team => {
            table[team.id] = {
                team: team.name,
                amount: 0,
                category: team.categories[0].name,
                group: team.group,
                id: team.id,
                matches: team.played_against
            }
        })

        data.map((round) => {
            round.matches.map(((match) => {
                match.teams.map(team => {
                    if (team.id in table) {
                        table[team.id].amount += 1
                    }
                })

            }))
        })

        setTable(table)

    }, [])
    return (
        <>
            {console.log(showDetails)}
            {showDetails &&
                <DefaultModal
                    title={`Verificar partidas do time ${showDetails.team} da categoria ${showDetails.category} grupo ${showDetails.group}`}
                    dialogProps={{ maxWidth: 'md' }}
                    handleClose={() => setShowDetails(null)}
                    content={
                        <List>
                            {Object.values(showDetails.matches)?.map(match =>
                                <ListItem>
                                    {dayjs(match.date).toDate().toLocaleDateString()}, {weekdayDePara[dayjs(match.date).$W]} - {match.name} - categoria {match.category} - grupo {match.group}
                                </ListItem>)}
                        </List>
                    }
                />}
            <Box className='teams-container' sx={{ minHeight: '30rem', maxHeight: '35rem' }}>
                {table && <DataGrid
                    rows={Object.values(table)}
                    columns={columns(setShowDetails)}
                    pagination
                    density='compact'
                    slots={{ toolbar: GridToolbar }}
                />}
            </Box>
        </>

    )
}

export default DisplayMatches