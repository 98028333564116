const Header = () => {
    return (
        <section>
            <div className="text-center">
                <h1>Tabela da copa</h1>
            </div>
        </section>
    );
};

export default Header;