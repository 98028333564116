import React, { useEffect, useState } from 'react'
import { TextField, Box, IconButton } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete';
import { api } from '../../services/api'
import ColorButton from '../Buttons/ColorButton';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

let timer = 0

const columns = (parent, setParent, addTeam, removeTeam) => {
    return ([
        {
            field: 'name',
            headerName: 'Nome',
            flex: .4
        },
        {
            field: 'categories',
            headerName: 'Categoria',
            flex: .4,
            valueGetter: ({ value }) => {
                return value?.map(each => each.name).join(', ')
            }
        },
        {
            field: 'actions',
            headerName: 'Ações',
            flex: .2,
            renderCell: (props) => {

                return (
                    <Box >
                        <IconButton onClick={() => { setParent({ teams: parent.teams.filter(each => each.id !== props.id) }); setTimeout(() => removeTeam(props.row), 100)  }}><DeleteIcon /></IconButton>
                        <IconButton onClick={() => { addTeam(props.row) }}><AddIcon /></IconButton>
                    </Box>
                )
            }
        }
    ])
}

const searchItem = async (inputField, setTeamsList) => {
    const res = await api.get(`/teams/?search=${inputField}`)
    if (res.status === 200)
        setTeamsList(res.data.results.map(each => ({ label: `${each.name} - ${each.categories?.map(each => each.name).join(', ')}`, id: each.id, data: each, points: 0 })))
}

const loadAllItems = async (setParent, setLoader) => {
    setLoader(true)
    const res = await api.get('/teams/')
    setLoader(false)
    if (res.status === 200){
        const data = res.data.results.map(each => ({ label: `${each.name} - ${each.categories?.map(each => each.name).join(', ')}`, id: each.id, data: each, points: 0 }))
        setParent({teams: data.map(each => each.data)})
    }
}

const pushSelectedTeam = (parent, setParent, foundProduct, setFoundProduct) => {
    if (foundProduct === '') return
    if (!foundProduct) return
    if (parent?.teams?.map(each => each.id).includes(foundProduct?.id)) return

    setParent({ teams: [...parent.teams, foundProduct.data] })
    setFoundProduct('')
}

const TeamsTable = ({ parent, setParent, setLoader, addTeam, removeTeam }) => {
    const [inputField, setInputField] = useState('')
    const [foundProduct, setFoundProduct] = useState('')
    const [teamsList, setTeamsList] = useState([])

    useEffect(() => {
        clearTimeout(timer)
        timer = setTimeout(() => searchItem(inputField, setTeamsList), 1000)

        return (() => {
            clearTimeout(timer)
        })

    }, [inputField, setTeamsList])

    return (
        <Box className='teams-container' sx={{ minHeight: '30rem', maxHeight:'35rem' }}>
            <ColorButton  onClick={() => loadAllItems(setParent, setLoader)}>
                Adicionar todos os Times
            </ColorButton>
            <Box className='school-header'>
                <Autocomplete
                    sx={{ flex: 1 }}
                    id={'autocompletebox-'}
                    value={foundProduct ? foundProduct : ''}
                    options={teamsList.filter(each => !parent.teams.map(each => each.id).includes(each.id))}
                    onChange={(e, selectedProduct) => { setFoundProduct(selectedProduct) }}
                    inputValue={inputField}
                    freeSolo
                    autoHighlight
                    handleHomeEndKeys
                    onInputChange={(e, v) => setInputField(v)}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            id={'autocompleteinput'}
                            type="text"
                            sx={{ backgroundColor: 'white' }}
                            label="Time"
                            size="small"
                            fullWidth={true}
                        />
                    }
                />

                <ColorButton sx={{ flex: .5 }} onClick={() => pushSelectedTeam(parent, setParent, foundProduct, setFoundProduct)}
                    disabled={foundProduct === '' || !foundProduct}>
                    Adicionar Time
                </ColorButton>

            </Box>
            
            <DataGrid
                rows={parent.teams}
                columns={columns(parent, setParent, addTeam, removeTeam)}
                density='compact'
                slots={{ toolbar: GridToolbar }}
                fullWidth
            />
        </Box>
    )
}

export default TeamsTable